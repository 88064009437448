import React, { useState } from 'react';
import { TextField } from '$cmp/form/textField';

export interface PasswordFieldProps {
  value: string;
  onChange: (value: string) => any;
  isDirty: boolean;
  disabled?: boolean;
}

export const PasswordField: React.FunctionComponent<PasswordFieldProps> = React.memo((props) => {
  const { value, onChange, isDirty, disabled } = props;

  const [showPassword, setShowPassword] = useState(false);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <TextField
        label=""
        type={showPassword ? 'text' : 'password'}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        fullWidth
        my={2}
        disabled={disabled}
        focused={isDirty ? true : undefined}
      />
      <label>
        <input
          type="checkbox"
          checked={showPassword}
          onChange={(e) => setShowPassword(e.target.checked)}
        />{' '}
        Show hidden
      </label>
    </div>
  );
});
