/* eslint-disable max-classes-per-file */
import { useEffect } from 'react';
import { Event } from "../../utils";
export class ProfileFieldValueChangeEvent extends Event {
    constructor(key) {
        super(ProfileFieldValueChangeEvent.EVENT_NAME);
        this.key = key;
    }
}
ProfileFieldValueChangeEvent.EVENT_NAME = 'ProfileFieldValueChangeEvent';
export class ProfileFieldGroupListItemsChangeEvent extends Event {
    constructor(key) {
        super(ProfileFieldGroupListItemsChangeEvent.EVENT_NAME);
        this.key = key;
    }
}
ProfileFieldGroupListItemsChangeEvent.EVENT_NAME = 'ProfileFieldGroupListItemsChangeEvent';
export class ProfileFieldGroupListItemsReloadEvent extends Event {
    constructor(key) {
        super(ProfileFieldGroupListItemsReloadEvent.EVENT_NAME);
        this.key = key;
    }
}
ProfileFieldGroupListItemsReloadEvent.EVENT_NAME = 'ProfileFieldGroupListItemsReloadEvent';
export class ProfileSaveEvent extends Event {
    constructor() {
        super(ProfileSaveEvent.EVENT_NAME);
    }
}
ProfileSaveEvent.EVENT_NAME = 'ProfileSaveEvent';
export const useEventTargetHandler = (eventTarget, eventName, handlerConstructor, deps) => {
    useEffect(() => {
        const eventHandler = handlerConstructor();
        eventTarget.addEventListener(eventName, eventHandler);
        return () => {
            eventTarget.removeEventListener(eventName, eventHandler);
        };
    }, deps);
};
