import React, { useEffect, useRef, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import {
  ProfilePageContextProvider,
  ProfilePageAutosaver,
  useProfilePageMenuItemContext,
  useProfileContext,
} from '@quiet-sunset/leo-shared';
import { LoadingSpinner } from '$cmp/loadingSpinner';
import { ProfileContentPage } from './profileContentPage';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import getScrollParent from '../../../lib/getScrollParent';

const SliderViewport = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
`;

const SlidingComponentContainer = styled(animated.div)`
  position: relative;
  white-space: nowrap;
  & > * {
    white-space: unset;
  }
`;

const SlidingComponent = styled.div`
  width: 100%;
  display: inline-block;
  vertical-align: top;
`;

export const ProfileContentSlider: React.FunctionComponent = () => {
  const history = useHistory();
  const { profileId } = useProfileContext();
  const { menuItemId, pageStack, currentPageStackItem } = useProfilePageMenuItemContext();
  const [activeStackIndex, setActiveStackIndex] = useState(pageStack.length - 1);
  const pageRefs = useRef<(HTMLDivElement | null)[]>([]);

  const slidingComponentContainerStyles = useSpring({
    left: `${activeStackIndex * -100}%`,
  });

  useEffect(() => {
    setActiveStackIndex((prevActiveStackIndex) =>
      Math.min(prevActiveStackIndex, pageStack.length - 1)
    );
  }, [pageStack]);

  useEffect(() => {
    const q = new URLSearchParams();
    if (currentPageStackItem.profileFieldGroupListItemId) {
      q.set('profileFieldGroupListItemId', currentPageStackItem.profileFieldGroupListItemId);
    }
    if (currentPageStackItem.isListChildItem) {
      q.set('isListChildItem', 'true');
    }
    const qs = q.toString();
    history.push(
      `/profiles/${profileId}/${menuItemId}/${currentPageStackItem.fieldGroupId}${
        qs.length ? '?' : ''
      }${qs}`
    );
  }, [profileId, menuItemId, currentPageStackItem]);

  useEffect(() => {
    getScrollParent(pageRefs.current[activeStackIndex])?.scrollTo({ top: 0 });
  }, [activeStackIndex]);

  return (
    <SliderViewport>
      <SlidingComponentContainer style={slidingComponentContainerStyles}>
        {pageStack.map((pageStackItem, i) => (
          <SlidingComponent
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            ref={(el) => {
              pageRefs.current[i] = el;
            }}
          >
            <ProfilePageContextProvider
              rootFieldGroupId={pageStackItem.fieldGroupId}
              rootProfileFieldGroupListItemId={pageStackItem.profileFieldGroupListItemId}
              isListChildItem={pageStackItem.isListChildItem}
              Loading={LoadingSpinner}
              onLoad={() => setActiveStackIndex(i)}
            >
              <ProfilePageAutosaver />
              <ProfileContentPage />
            </ProfilePageContextProvider>
          </SlidingComponent>
        ))}
      </SlidingComponentContainer>
    </SliderViewport>
  );
};
