import { jsx as _jsx } from "react/jsx-runtime";
import { useAsyncMemo } from "../../hooks";
import { useMenuItemsService } from "../../services";
import React, { useCallback, useContext, useEffect, useState } from 'react';
// the default value for the context is meaningless because the context itself isn't exported
// so the only way to construct one is by using the `<ProfilePageContextProvider />` component
// which creates its own value
const ProfilePageMenuItemContext = React.createContext(null);
ProfilePageMenuItemContext.displayName = 'ProfilePageMenuItemContext';
export const useProfilePageMenuItemContext = () => useContext(ProfilePageMenuItemContext);
export const ProfilePageMenuItemContextProvider = ({ menuItemId, Loading, children }) => {
    const MenuItemsService = useMenuItemsService();
    const [menuItem] = useAsyncMemo(() => MenuItemsService.getMenuItemById(menuItemId), [menuItemId]);
    const [pageStack, setPageStack] = useState();
    const currentPageStackItem = pageStack === null || pageStack === void 0 ? void 0 : pageStack[pageStack.length - 1];
    useEffect(() => {
        if ((menuItem === null || menuItem === void 0 ? void 0 : menuItem.field_group_id) && !pageStack) {
            setPageStack([
                {
                    fieldGroupId: menuItem.field_group_id,
                    isListChildItem: false,
                    profileFieldGroupListItemId: null,
                    label: menuItem.label,
                },
            ]);
        }
    }, [menuItem, pageStack]);
    const push = useCallback((item) => setPageStack((old) => (old ? [...old, item] : old)), []);
    const pop = useCallback(() => setPageStack((old) => (old && old.length > 1 ? old.slice(0, -1) : old)), []);
    const popToIndex = useCallback((targetIndex) => setPageStack((old) => (old && old.length > 1 ? old.slice(0, targetIndex + 1) : old)), []);
    if (!menuItem || !pageStack || !currentPageStackItem) {
        return Loading ? _jsx(Loading, {}, void 0) : null;
    }
    const contextValue = {
        menuItemId,
        menuItem,
        pageStack,
        currentPageStackItem,
        push,
        pop,
        popToIndex,
    };
    return (_jsx(ProfilePageMenuItemContext.Provider, Object.assign({ value: contextValue }, { children: children }), void 0));
};
