import React, { VFC } from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import { LeoHeaderLogo } from '$cmp/leoHeaderLogo';

const Backdrop = styled('div')`
  height: 100%;
  width: 100%;
  padding: 25px;
  background-color: #121a40;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
`;

export const MobileRedirectRoute: VFC = () => {
  const redirectToApp = () => {
    window.location.href = 'leo://home';
  };

  return (
    <Backdrop>
      <LeoHeaderLogo variant="light" />
      <h3>Please tap the button below to return to the app.</h3>
      <Button onClick={redirectToApp} variant="contained">
        Return to app
      </Button>
    </Backdrop>
  );
};
