import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { User } from '@quiet-sunset/leo-shared';

type UserGridRow = User;

export const LeoProUserTable: React.FunctionComponent<{
  users: User[];
}> = ({ users }) => {
  const [userGridRows, setUsers] = useState(null as UserGridRow[] | null);

  useEffect(() => {
    void (async () => {
      const newUserGridRows = users.filter((user) => user.is_leo_pro_enabled);
      setUsers(newUserGridRows);
    })();
  }, [users]);

  const columns = useMemo<GridColumns<UserGridRow>>(
    () =>
      [
        { field: 'id', headerName: 'ID', width: 300 },
        { field: 'email', headerName: 'Email', width: 250 },
        { field: 'first_name', headerName: 'First name', width: 150 },
        { field: 'last_name', headerName: 'Last name', width: 150 },
        {
          field: 'created_date',
          headerName: 'Signup date',
          width: 100,
          renderCell: ({ value }) => (value != null ? dayjs.utc(value).local().format('L') : ''),
        },
        {
          field: 'last_activity_date',
          headerName: 'Last activity date',
          width: 125,
          renderCell: ({ value }) => (value != null ? dayjs.utc(value).local().format('L') : ''),
        },
      ] as GridColumns<UserGridRow>,
    []
  );

  if (userGridRows == null) {
    return null;
  }

  return (
    <div style={{ height: '400px' }}>
      <DataGrid columns={columns} rows={userGridRows} />
    </div>
  );
};
