import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import {
  useFieldGroup,
  useProfileContext,
  useProfileFieldGroupListItems,
  useProfileNavigatorContext,
  useProfilePageMenuItemContext,
} from '@quiet-sunset/leo-shared';
import { List, ListItem, ListItemContent } from '$cmp/list';
// eslint-disable-next-line import/no-cycle
import { RenameFieldGroupListItemDialog } from '../fieldGroups/vertical/renameFieldGroupListItemDialog';

export interface ProfileContentListSliderProps {
  fieldGroupId: string;
  parentProfileFieldGroupListItemId: string | null;
}

export const ProfileContentListSlider: React.FunctionComponent<ProfileContentListSliderProps> = (
  props
) => {
  const { fieldGroupId, parentProfileFieldGroupListItemId } = props;

  const { menuItemId, push } = useProfilePageMenuItemContext();

  const [
    profileFieldGroupListItems,
    addProfileFieldGroupListItem,
    deleteProfileFieldGroupListItem,
  ] = useProfileFieldGroupListItems(fieldGroupId, parentProfileFieldGroupListItemId);

  const { baseRoute, profileId } = useProfileContext();
  const { reloadCurrentNavigatorState } = useProfileNavigatorContext();
  const [renamingItemId, setRenamingItemId] = useState<string>();
  const fieldGroup = useFieldGroup(fieldGroupId);

  const onRenameDialogClose = useCallback(() => {
    setRenamingItemId(undefined);
    reloadCurrentNavigatorState();
  }, [reloadCurrentNavigatorState]);

  if (!fieldGroup) {
    return null;
  }

  return (
    <>
      {renamingItemId && (
        <RenameFieldGroupListItemDialog
          profileId={profileId}
          fieldGroupId={fieldGroupId}
          profileFieldGroupListItemId={renamingItemId}
          parentProfileFieldGroupListItemId={parentProfileFieldGroupListItemId}
          onClose={onRenameDialogClose}
        />
      )}
      <div style={{ margin: '30px' }}>
        <List transparent>
          {(profileFieldGroupListItems ?? []).map((item) => (
            <ListItem key={item.id}>
              <Link
                to={`${baseRoute}/${menuItemId}/${fieldGroupId}?listItemId=${parentProfileFieldGroupListItemId}&childListItemId=${item.id}`}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  push({
                    fieldGroupId,
                    isListChildItem: true,
                    profileFieldGroupListItemId: item.id,
                    label: item.title ?? 'New item',
                  });
                }}
              >
                <ListItemContent>
                  <h5 style={{ flex: 1, color: 'black' }}>{item.title}</h5>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setRenamingItemId(item.id);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      deleteProfileFieldGroupListItem(item.id);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemContent>
              </Link>
            </ListItem>
          ))}
          <ListItem onClick={addProfileFieldGroupListItem}>
            <ListItemContent>
              <AddIcon />
              Add new
            </ListItemContent>
          </ListItem>
        </List>
      </div>
    </>
  );
};
