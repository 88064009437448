var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useRef } from 'react';
import { useAxiosService } from './axiosService';
import { useUtilitiesService } from './utilitiesService';
export const useProfilesService = () => {
    const axios = useAxiosService();
    const UtilitiesService = useUtilitiesService();
    const { current: ProfilesService } = useRef({
        getProfiles() {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`profiles`);
                return data;
            });
        },
        getProfileById(profileId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`profiles/${profileId}`);
                return data;
            });
        },
        getLeoProOrganizationProfileForProfile(profileId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`profiles/${profileId}/leo-pro-organization-profile`);
                return data;
            });
        },
        updateLeoProOrganizationProfileSubject(profileId, email) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.put(`profiles/${profileId}/leo-pro-organization-profile/subject`, { email });
            });
        },
        removeLeoProOrganizationProfileSubject(profileId) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.delete(`profiles/${profileId}/leo-pro-organization-profile/subject`);
            });
        },
        disconnectProfileFromLeoPro(profileId) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.delete(`profiles/${profileId}/leo-pro-organization-profile`);
            });
        },
        createProfile() {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.post(`profiles`);
            });
        },
        deleteProfileById(profileId) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.delete(`profiles/${profileId}`);
            });
        },
        getProfileSelectedMenuItemsForProfile(profileId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`profiles/${profileId}/menu-items`);
                return data;
            });
        },
        selectMenuItemForProfile(profileId, menuItemId) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.put(`profiles/${profileId}/menu-items/${menuItemId}`);
            });
        },
        deselectMenuItemForProfile(profileId, menuItemId) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.delete(`profiles/${profileId}/menu-items/${menuItemId}`);
            });
        },
        getMenuItemAccessForProfileAndMenuItem(profileId, menuItemId) {
            var _a;
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    const { data } = yield axios.get(`profiles/${profileId}/menu-items/${menuItemId}`);
                    return data;
                }
                catch (e) {
                    if (((_a = e.response) === null || _a === void 0 ? void 0 : _a.status) === 404) {
                        return {
                            has_unrestricted_access: false,
                            has_access: false,
                            can_edit: false,
                        };
                    }
                    throw e;
                }
            });
        },
        getHasProfileCertificationModelByProfileId(profileId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`profiles/${profileId}/profile-certification-model/exists`);
                const { exists } = data;
                return exists;
            });
        },
        saveVisibleMenuItemsForProfile(profileId, profileVisibleMenuItems, userId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.put(`profiles/${profileId}/users/${userId}/menu-items`, profileVisibleMenuItems);
                return data;
            });
        },
        getTrustedUserByProfileAndUser(profileId, userId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`profiles/${profileId}/trusted-users/user/${userId}`);
                return data;
            });
        },
        getTrustedUserForProfile(profileId, trustedUserType, profileFieldGroupListItemId) {
            var _a;
            return __awaiter(this, void 0, void 0, function* () {
                const queryString = profileFieldGroupListItemId != null
                    ? `?profileFieldGroupListItemId=${profileFieldGroupListItemId}`
                    : '';
                try {
                    const { data } = yield axios.get(`profiles/${profileId}/trusted-users/${trustedUserType}${queryString}`);
                    return data;
                }
                catch (e) {
                    if (((_a = e.response) === null || _a === void 0 ? void 0 : _a.status) === 404) {
                        return null;
                    }
                    throw e;
                }
            });
        },
        getFullTrustedUserForProfile(profileId, trustedUserType, profileFieldGroupListItemId) {
            var _a;
            return __awaiter(this, void 0, void 0, function* () {
                const queryString = profileFieldGroupListItemId != null
                    ? `?profileFieldGroupListItemId=${profileFieldGroupListItemId}`
                    : '';
                try {
                    const { data } = yield axios.get(`profiles/${profileId}/trusted-users/${trustedUserType}/full${queryString}`);
                    return data;
                }
                catch (e) {
                    if (((_a = e.response) === null || _a === void 0 ? void 0 : _a.status) === 404) {
                        return null;
                    }
                    throw e;
                }
            });
        },
        saveTrustedUserForProfile(profileId, trustedUserType, profileFieldGroupListItemId, trustedUserEditModel) {
            return __awaiter(this, void 0, void 0, function* () {
                const queryString = profileFieldGroupListItemId != null
                    ? `?profileFieldGroupListItemId=${profileFieldGroupListItemId}`
                    : '';
                yield axios.put(`profiles/${profileId}/trusted-users/${trustedUserType}${queryString}`, trustedUserEditModel);
            });
        },
        deleteTrustedUserForProfile(profileId, trustedUserType, profileFieldGroupListItemId) {
            var _a;
            return __awaiter(this, void 0, void 0, function* () {
                const queryString = profileFieldGroupListItemId != null
                    ? `?profileFieldGroupListItemId=${profileFieldGroupListItemId}`
                    : '';
                try {
                    yield axios.delete(`profiles/${profileId}/trusted-users/${trustedUserType}${queryString}`);
                }
                catch (e) {
                    if (((_a = e.response) === null || _a === void 0 ? void 0 : _a.status) === 404) {
                        return;
                    }
                    throw e;
                }
            });
        },
        unlockProtectedProfileForTrustedUser(profileId, trustedUserType, profileFieldGroupListItemId) {
            return __awaiter(this, void 0, void 0, function* () {
                const queryString = profileFieldGroupListItemId != null
                    ? `?profileFieldGroupListItemId=${profileFieldGroupListItemId}`
                    : '';
                yield axios.post(`profiles/${profileId}/trusted-users/${trustedUserType}/unlock${queryString}`);
            });
        },
        lockProtectedProfileForTrustedUser(profileId, trustedUserType, profileFieldGroupListItemId) {
            return __awaiter(this, void 0, void 0, function* () {
                const queryString = profileFieldGroupListItemId != null
                    ? `?profileFieldGroupListItemId=${profileFieldGroupListItemId}`
                    : '';
                yield axios.post(`profiles/${profileId}/trusted-users/${trustedUserType}/lock${queryString}`);
            });
        },
        unlockProtectedProfileForSelf(profileId, unlockCode) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.post(`profiles/${profileId}/trusted-users/unlock-self`, {
                    unlockCode,
                });
            });
        },
        uploadAppointmentDocumentFileForTrustedUserForProfile(profileId, trustedUserType, profileFieldGroupListItemId, file) {
            return __awaiter(this, void 0, void 0, function* () {
                const formData = UtilitiesService.objectToFormData({
                    customAgreementFile: file,
                });
                const queryString = profileFieldGroupListItemId != null
                    ? `?profileFieldGroupListItemId=${profileFieldGroupListItemId}`
                    : '';
                yield axios.put(`profiles/${profileId}/trusted-users/${trustedUserType}/appointment-document${queryString}`, formData);
            });
        },
        deleteAppointmentDocumentFileForTrustedUserForProfile(profileId, trustedUserType, profileFieldGroupListItemId) {
            return __awaiter(this, void 0, void 0, function* () {
                const queryString = profileFieldGroupListItemId != null
                    ? `?profileFieldGroupListItemId=${profileFieldGroupListItemId}`
                    : '';
                yield axios.delete(`profiles/${profileId}/trusted-users/${trustedUserType}/appointment-document${queryString}`);
            });
        },
        getPdfLinkForProfileById(profileId, menuItemIds) {
            return __awaiter(this, void 0, void 0, function* () {
                const postBody = {
                    menu_item_ids: menuItemIds,
                };
                const { data } = yield axios.post(`profiles/${profileId}/pdf`, postBody);
                return data;
            });
        },
        setProfileCertificationModelByProfileId(profileId, profileCertificationModel) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.put(`profiles/${profileId}/profile-certification-model`, profileCertificationModel);
                return data;
            });
        },
        clearProfileCertificationModelByProfileId(profileId) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.delete(`profiles/${profileId}/profile-certification-model`);
            });
        },
        getPdfLinkForCertificationModelByProfileId(profileId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.post(`profiles/${profileId}/profile-certification-model/pdf`);
                return data;
            });
        },
    });
    return ProfilesService;
};
