import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useEmailVerificationForm, useSignOut } from '@sprice237/react-auth';
import { ButtonContainer } from "../../ui/ButtonContainer";
import { LoadingOverlay } from "../../ui/LoadingSpinner";
export const EmailVerificationForm = memo(() => {
    const signOut = useSignOut();
    const { error, inProgress, isComplete, submit } = useEmailVerificationForm();
    return (_jsx(LoadingOverlay, Object.assign({ isLoading: inProgress }, { children: _jsxs(Box, Object.assign({ mx: 10, my: 5 }, { children: [error && _jsx(Alert, Object.assign({ severity: "error" }, { children: error }), void 0), isComplete && (_jsx(Alert, Object.assign({ severity: "success" }, { children: "Please check your email for a verification message." }), void 0)), _jsx(Box, Object.assign({ my: 3 }, { children: _jsx(Typography, { children: "Your email address must be verified. Please click the button below to send a verification email to your email address." }, void 0) }), void 0), _jsxs(ButtonContainer, { children: [_jsx(Button, Object.assign({ onClick: () => submit(), variant: "contained", color: "primary" }, { children: "Send verification email" }), void 0), _jsx(Button, Object.assign({ onClick: signOut, component: Link, color: "primary" }, { children: "Back to login" }), void 0)] }, void 0)] }), void 0) }), void 0));
});
