import React from 'react';
import styled from 'styled-components';
import { MenuItem, Select as MuiSelect } from '@mui/material';
import { spacing, SpacingProps } from '@mui/system';

import {
  useProfileFieldGroupListItemsService,
  useAsyncMemo,
  useProfileContext,
} from '@quiet-sunset/leo-shared';

interface SelectMuiType extends SpacingProps {
  mt?: number;
}

const Select = styled(MuiSelect)<SelectMuiType>(spacing);

export interface SingleSelectFieldGroupFieldProps {
  value: string | null;
  fieldGroupId: string;
  onChange: (value: string | null) => any;
  isDirty: boolean;
  disabled?: boolean;
}

export const SingleSelectFieldGroupField: React.FunctionComponent<SingleSelectFieldGroupFieldProps> =
  React.memo((props) => {
    const { profileId } = useProfileContext();
    const { value, fieldGroupId, onChange, disabled } = props;

    const ProfileFieldGroupListItemsService = useProfileFieldGroupListItemsService();

    const [profileFieldGroupListItems] = useAsyncMemo(
      () => ProfileFieldGroupListItemsService.getAllForProfile(profileId, fieldGroupId),
      [profileId, fieldGroupId]
    );

    if (!profileFieldGroupListItems) {
      return <div>...</div>;
    }

    return (
      <Select
        disabled={disabled}
        value={JSON.stringify(value)}
        onChange={(e) => onChange(JSON.parse(e.target.value as string))}
        fullWidth
        variant="standard"
      >
        <MenuItem value="null">(none)</MenuItem>
        {profileFieldGroupListItems.map((item) => (
          <MenuItem key={item.id} value={JSON.stringify(item.id)}>
            {item.title}
          </MenuItem>
        ))}
      </Select>
    );
  });
