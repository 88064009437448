import React, { useEffect, useState } from 'react';
import { User, useUpdateTrigger, useUserService } from '@quiet-sunset/leo-shared';

import { LoadingSpinner } from '$cmp/loadingSpinner';
import { SingleSubscriberUserTable } from './singleSubscriberUserTable';
import { LeoProUserTable } from './leoProUserTable';
import { InvalidModeUserTable } from './invalidModeUserTable';

export const UserTable: React.FunctionComponent = () => {
  const UserService = useUserService();

  const [users, setUsers] = useState(null as User[] | null);
  const [updateTrigger, triggerUpdate] = useUpdateTrigger();

  useEffect(() => {
    void (async () => {
      setUsers(null);
      const _users = await UserService.getAllUsers();
      setUsers(_users);
    })();
  }, [updateTrigger]);

  return (
    <>
      <h3>Users</h3>

      {!users && <LoadingSpinner />}

      {users && (
        <>
          <h4>Single subscriber users</h4>
          <SingleSubscriberUserTable users={users} onUpdate={triggerUpdate} />
          <h4>LEO-PRO users</h4>
          <LeoProUserTable users={users} />
          <h4>Users without single-subscriber or LEO-PRO enabled</h4>
          <InvalidModeUserTable users={users} />
        </>
      )}
    </>
  );
};
