import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useProfileContext } from '@quiet-sunset/leo-shared';

import { Card } from '$cmp/card';
import { CardBackdrop } from '$cmp/cardBackdrop';
import { AcknowledgeModal } from '$cmp/modals/acknowledgeModal';
import { CertificationModal } from '$cmp/modals/certificationModal';
import { CertificationUnlockModal } from '$cmp/modals/certificationUnlockModal';
import { CertificationDownloadModal } from '$cmp/modals/certificationDownloadModal';
import { PdfDownloadModal } from '$cmp/modals/pdfDownloadModal';
import { UnlockProtectedModeModal } from '$cmp/modals/unlockProtectedModeModal';
import { ContextualHiddenSidebarContentContainer } from '$cmp/sidebar/contextualHiddenSidebarContentContainer';
import { ProfileSidebar } from '$cmp/sidebar/profileSidebar';

import { useSetterUnsetter } from '$hooks/useSetterUnsetter';
import { useToggle } from '$hooks/useToggle';

import { PaymentAccountStatusAlert } from '$routes/managePaymentAccount/paymentAccountStatusAlert';

import { ProfileContentRouter } from './profileContentRouter';
import { ProfileLockedAlert } from './profileLockedAlert';

const ToggleSidebarVisibilityMenu = styled.div`
  display: none;
  ${(props) => props.theme.breakpoints.down('sm')} {
    display: block;
  }
`;

export const ProfileContainer: React.FunctionComponent = () => {
  const history = useHistory();
  const { profileId, hasProfileCertificationModel } = useProfileContext();

  const [hiddenSidebarIsVisible, toggleHiddenSidebarVisibility] = useToggle(false);

  const [certificationModalIsVisible, launchCertificationModal, hideCertificationModal] =
    useSetterUnsetter();
  const [
    certificationUnlockModalIsVisible,
    showCertificationUnlockModal,
    hideCertificationUnlockModal,
  ] = useSetterUnsetter();
  const [
    certificationDownloadModalIsVisible,
    showCertificationDownloadModal,
    hideCertificationDownloadModal,
  ] = useSetterUnsetter();
  const [pdfDownloadModalIsVisible, launchPdfDownloadModal, hidePdfDownloadModal] =
    useSetterUnsetter();
  const [
    unlockProtectedModeModalIsVisible,
    launchUnlockProtectedModeModal,
    hideUnlockProtectedModeModal,
  ] = useSetterUnsetter();

  if (!profileId) {
    return (
      <AcknowledgeModal
        title="Access denied"
        message="You do not have access to this profile, possibly due to payment issues."
        onAcknowledge={() => history.goBack()}
      />
    );
  }

  return (
    <CardBackdrop>
      {pdfDownloadModalIsVisible && (
        <PdfDownloadModal profileId={profileId} onClose={hidePdfDownloadModal} />
      )}
      {certificationModalIsVisible && <CertificationModal onClose={hideCertificationModal} />}
      {certificationUnlockModalIsVisible && (
        <CertificationUnlockModal onClose={hideCertificationUnlockModal} />
      )}
      {certificationDownloadModalIsVisible && (
        <CertificationDownloadModal onClose={hideCertificationDownloadModal} />
      )}
      {unlockProtectedModeModalIsVisible && (
        <UnlockProtectedModeModal onClose={hideUnlockProtectedModeModal} />
      )}
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <ToggleSidebarVisibilityMenu>
          <Navbar expand="lg" variant="dark">
            <Nav className="mr-auto">
              <Nav.Link onClick={toggleHiddenSidebarVisibility}>View menu</Nav.Link>
            </Nav>
          </Navbar>
        </ToggleSidebarVisibilityMenu>
        <div style={{ flex: '1', overflow: 'auto' }}>
          <ContextualHiddenSidebarContentContainer
            sidebarWidth={300}
            isOpen={hiddenSidebarIsVisible}
            sidebar={
              <ProfileSidebar
                launchCertificationModal={launchCertificationModal}
                launchPdfDownloadModal={launchPdfDownloadModal}
                launchUnlockProtectedModeModal={launchUnlockProtectedModeModal}
                showCertificationDownloadModal={showCertificationDownloadModal}
                showCertificationUnlockModal={showCertificationUnlockModal}
              />
            }
          >
            {hasProfileCertificationModel && (
              <Card transparentBorder>
                <ProfileLockedAlert onUnlock={showCertificationUnlockModal} />
              </Card>
            )}

            <Card transparentBorder>
              <PaymentAccountStatusAlert />
            </Card>

            <ProfileContentRouter />
          </ContextualHiddenSidebarContentContainer>
        </div>
      </div>
    </CardBackdrop>
  );
};
