import styled from 'styled-components';

export const List = styled.ul<{ selectable?: boolean; transparent?: boolean }>`
  padding: 0;
  & li {
    list-style-type: none;
    background-color: ${({ selectable, transparent }) => {
      if (transparent) {
        return 'transparent';
      }
      return selectable ? '#ccc' : '#eee';
    }};
  }
`;
