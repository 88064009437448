var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect } from 'react';
import { useApplyActionCode } from '@sprice237/react-auth';
import { useAuthActionParams } from './hooks';
export const AuthActionHandler = ({ onResetPasswordAction, onVerifyEmailAction, onNoAction, }) => {
    const authActionParams = useAuthActionParams();
    const applyActionCode = useApplyActionCode();
    const onVerifyEmail = (firebaseActionParams) => __awaiter(void 0, void 0, void 0, function* () {
        yield applyActionCode(firebaseActionParams.oobCode);
        if (onVerifyEmailAction) {
            onVerifyEmailAction(firebaseActionParams);
        }
    });
    useEffect(() => {
        if (!authActionParams) {
            return;
        }
        const { mode, oobCode } = authActionParams;
        if (mode === 'resetPassword' && onResetPasswordAction) {
            onResetPasswordAction(authActionParams);
        }
        else if (mode === 'verifyEmail' && oobCode) {
            onVerifyEmail(authActionParams).catch(console.error);
        }
        else {
            onNoAction();
        }
    }, [authActionParams]);
    return null;
};
