import React, { useState, useMemo } from 'react';
import { useRouteMatch, generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MuiCheckCircleIcon from '@mui/icons-material/CheckCircle';
import MuiCancelIcon from '@mui/icons-material/Cancel';

import {
  useAsyncMemo,
  useMenuItemsService,
  useProfileContext,
  useProfileNavigatorContext,
  useProfilesService,
} from '@quiet-sunset/leo-shared';

import { List, ListItem, ListItemContent } from '$cmp/list';
import { LoadingSpinner } from '$cmp/loadingSpinner';
import { useUpdateTrigger } from '$hooks/useUpdateTrigger';

const CheckCircleIcon = styled(MuiCheckCircleIcon)`
  color: #0c0;
`;

const CancelIcon = styled(MuiCancelIcon)`
  color: #c00;
`;

const FlexContainer = styled.div`
  display: flex;
`;

const MenuItemsListContainer = styled.div`
  margin: auto;
  flex: 0 1 1000px;
  padding: 0px 10px;
`;

export const ProfileScopeSelectionMenuItemRoute: React.FunctionComponent = () => {
  const {
    params: { menuItemId },
    path: routePath,
  } = useRouteMatch<{ menuItemId: string }>();
  const MenuItemsService = useMenuItemsService();
  const ProfilesService = useProfilesService();

  const { profileId } = useProfileContext();
  const { reloadCurrentNavigatorState } = useProfileNavigatorContext();

  const [isLoading, setIsLoading] = useState(false);

  const [menuItem] = useAsyncMemo(
    () => MenuItemsService.getMenuItemById(menuItemId),
    [MenuItemsService, menuItemId]
  );

  const [childMenuItems] = useAsyncMemo(
    () => MenuItemsService.getMenuItemsByParentId(menuItemId),
    [MenuItemsService, menuItemId]
  );

  const [selectedChildMenuItemsUpdateTrigger, selectedChildMenuItemsTriggerUpdate] =
    useUpdateTrigger();
  const [selectedChildMenuItems] = useAsyncMemo(
    () => MenuItemsService.getMenuItemsByParentIdForProfile(profileId, menuItemId),
    [MenuItemsService, profileId, menuItemId, selectedChildMenuItemsUpdateTrigger]
  );

  const selectedMenuItemIds = useMemo(
    () => selectedChildMenuItems?.map((x) => x.id) ?? [],
    [selectedChildMenuItems]
  );

  const [parentMenuItem] = useAsyncMemo(async () => {
    return menuItem?.parent_menu_item_id
      ? await MenuItemsService.getMenuItemById(menuItem.parent_menu_item_id)
      : undefined;
  }, [MenuItemsService, menuItem]);

  const setMenuItemSelection = async (
    childMenuItemIdToToggle: string,
    newSelectedValue: boolean
  ) => {
    setIsLoading(true);

    if (newSelectedValue) {
      await ProfilesService.selectMenuItemForProfile(profileId, childMenuItemIdToToggle);
    } else {
      await ProfilesService.deselectMenuItemForProfile(profileId, childMenuItemIdToToggle);
    }

    setIsLoading(false);
    selectedChildMenuItemsTriggerUpdate();
    reloadCurrentNavigatorState();
  };

  if (!menuItem || !childMenuItems || !selectedMenuItemIds) {
    return <LoadingSpinner />;
  }

  const backRoute = menuItem.parent_menu_item_id
    ? generatePath(routePath, { menuItemId: menuItem.parent_menu_item_id })
    : `/profiles/${profileId}/sections`;

  return (
    <FlexContainer>
      <MenuItemsListContainer>
        {isLoading && <LoadingSpinner />}
        <Link to={backRoute}>
          <ArrowBackIosIcon />
          {parentMenuItem?.label ?? 'Home'}
        </Link>
        <h1>{menuItem.label}</h1>
        <List selectable style={{ margin: '0 50px' }}>
          {childMenuItems.map((childMenuItem) => {
            const isSelected = selectedMenuItemIds.includes(childMenuItem.id);
            return (
              <ListItem
                key={childMenuItem.id}
                isSelected={isSelected}
                onClick={() => setMenuItemSelection(childMenuItem.id, !isSelected)}
              >
                <ListItemContent>
                  <div style={{ marginRight: '15px' }}>
                    {isSelected && <CheckCircleIcon />}
                    {!isSelected && <CancelIcon />}
                  </div>
                  <h5 style={{ margin: 0, flex: 1 }}>{childMenuItem.label}</h5>
                  {isSelected && childMenuItem.has_children && (
                    <Link
                      to={generatePath(routePath, {
                        menuItemId: childMenuItem.id,
                      })}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <ArrowForwardIosIcon />
                    </Link>
                  )}
                </ListItemContent>
              </ListItem>
            );
          })}
        </List>
      </MenuItemsListContainer>
    </FlexContainer>
  );
};
