import { useLocation } from 'react-router-dom';
export const getAuthActionParams = (search) => {
    const searchObj = new URLSearchParams(search);
    const mode = searchObj.get('mode');
    const oobCode = searchObj.get('oobCode');
    const apiKey = searchObj.get('apiKey');
    const lang = searchObj.get('lang');
    const continueUrl = searchObj.get('continueUrl');
    if (!mode ||
        !['resetPassword', 'recoverEmail', 'verifyEmail'].includes(mode) ||
        !oobCode ||
        !apiKey ||
        !lang) {
        return null;
    }
    return { mode, oobCode, apiKey, lang, continueUrl };
};
export const useAuthActionParams = () => {
    const { search } = useLocation();
    return getAuthActionParams(search);
};
