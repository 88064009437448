import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  FirebaseAuthContextProvider,
  FirebaseAuthContextProviderProps,
} from '@sprice237/react-auth-dom';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

import 'draft-js/dist/Draft.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './style/main.scss';

import { LoadingSpinner } from '$cmp/loadingSpinner';
import { CurrentUserContextProvider } from '$contexts/currentUserContext';

import { App } from './app/App';
import { StripeLoader } from './app/stripeLoader';
import { ServiceLoader } from './app/serviceLoader';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

Bugsnag.start({
  apiKey: 'd556d96055cad56b57d6a330c07e08ff',
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ['production', 'staging'],
});

const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);

dayjs.extend(utc);
dayjs.extend(LocalizedFormat);

let firebaseOptions: FirebaseAuthContextProviderProps['firebaseOptions'];

if (
  !process.env.REACT_APP_ENVIRONMENT?.length ||
  process.env.REACT_APP_ENVIRONMENT === 'production' ||
  process.env.REACT_APP_ENVIRONMENT === 'staging'
) {
  firebaseOptions = {
    apiKey: 'AIzaSyDGoHrabOLaVTl8SOS_l_CF_5A_Tame4rY',
    authDomain: 'life-estate-organizer.firebaseapp.com',
    projectId: 'life-estate-organizer',
    storageBucket: 'life-estate-organizer.appspot.com',
    messagingSenderId: '656528045532',
    appId: '1:656528045532:web:d60c7846a99fb81c59cb19',
    measurementId: 'G-793M6QF02P',
  };
} else {
  firebaseOptions = {
    apiKey: 'AIzaSyAV-nTyrFLxvK3czbUNEbc6v2Fb8LdULAA',
    authDomain: 'life-estate-organizer-dev.firebaseapp.com',
    projectId: 'life-estate-organizer-dev',
    storageBucket: 'life-estate-organizer-dev.appspot.com',
    messagingSenderId: '366787696527',
    appId: '1:366787696527:web:9f410b968bf29dc8281dfa',
  };
}

ReactDOM.render(
  <ErrorBoundary>
    <React.StrictMode>
      <Router>
        <FirebaseAuthContextProvider firebaseOptions={firebaseOptions} Loading={LoadingSpinner}>
          <ServiceLoader>
            <CurrentUserContextProvider>
              <StripeLoader>
                <App />
              </StripeLoader>
            </CurrentUserContextProvider>
          </ServiceLoader>
        </FirebaseAuthContextProvider>
      </Router>
    </React.StrictMode>
  </ErrorBoundary>,
  document.getElementById('root')
);
