var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axiosStatic from 'axios';
import { useEffect, useRef } from 'react';
import URL from 'url-parse';
import { useServiceContext } from './ServiceContextProvider';
export const isAxiosError = (e) => !!e.isAxiosError;
export const useAxiosService = () => {
    const serviceContextValue = useServiceContext();
    const { apiUrl, retrieveToken, signOut } = serviceContextValue;
    const retrieveTokenRef = useRef(retrieveToken);
    const { current: axios } = useRef((() => {
        const _axios = axiosStatic.create({
            baseURL: apiUrl,
        });
        _axios.interceptors.request.use((config) => __awaiter(void 0, void 0, void 0, function* () {
            const token = yield retrieveTokenRef.current();
            if (token) {
                config.headers.authorization = `Bearer ${token}`;
            }
            return config;
        }));
        _axios.interceptors.response.use(undefined, (error) => {
            const { pathname } = new URL(error.request.responseURL);
            // if we're getting an error trying to use our auth token to hit /api/auth, that means our
            // firebase token is invalid for some reason and we will need to manually clear it
            // otherwise the client will continue to make requests with the same invalid firebase token.
            // there is one exception to this, and that is when the token is valid but the email is not verified.
            // in this case, we do NOT want to clear the firebase token, because we need to keep it so that the
            // user can request a verification email.
            // currently the api responds to this by returning an error that says "Invalid credentials", instead of
            // "Missing authentication". it's a bit brittle, we really should make the communication stronger than
            // that, but it does work.
            if (pathname === '/api/auth' &&
                isAxiosError(error) &&
                error.message === 'Missing authentication') {
                signOut();
            }
            throw error;
        });
        return _axios;
    })());
    useEffect(() => {
        retrieveTokenRef.current = retrieveToken;
    }, [retrieveToken]);
    return axios;
};
