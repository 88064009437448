import React, { useEffect, useMemo, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Config } from '$/config';
import { LoadingSpinner } from '$cmp/loadingSpinner';
import { UserName } from '$cmp/userName';

import type {
  LeoProOrganizationManager,
  LeoProOrganizationProfile,
} from '@quiet-sunset/leo-shared';

import {
  useLeoProOrganizationManagersService,
  useLeoProOrganizationsService,
} from '@quiet-sunset/leo-shared';

export const LeoProPaymentAccountBillingSummary: React.FunctionComponent<{
  leoProOrganizationId: string;
}> = ({ leoProOrganizationId }) => {
  const LeoProOrganizationManagersService = useLeoProOrganizationManagersService();
  const LeoProOrganizationsService = useLeoProOrganizationsService();

  const [isLoaded, setIsLoaded] = useState(false);
  const [leoProOrganizationManagers, setLeoProOrganizationManagers] = useState<
    LeoProOrganizationManager[] | null
  >(null);
  const [leoProOrganizationProfiles, setLeoProOrganizationProfiles] = useState<
    LeoProOrganizationProfile[] | null
  >(null);

  const leoProOrganizationManagersById = useMemo(
    () => new Map((leoProOrganizationManagers ?? []).map((manager) => [manager.id, manager])),
    [leoProOrganizationManagers]
  );

  const profileCountByManager = useMemo(() => {
    const profileCountsByManager = (leoProOrganizationProfiles ?? []).reduce(
      (map, leoProOrganizationProfile) => {
        const leoProOrganizationManager = leoProOrganizationManagersById.get(
          leoProOrganizationProfile.manager_id
        );
        const oldProfileCount = map.get(leoProOrganizationManager) ?? 0;
        const newProfileCount = oldProfileCount + 1;
        map.set(leoProOrganizationManager, newProfileCount);
        return map;
      },
      new Map<LeoProOrganizationManager | undefined, number>()
    );
    return profileCountsByManager;
  }, [leoProOrganizationManagersById, leoProOrganizationProfiles]);

  const totalCost = useMemo(
    () =>
      [...profileCountByManager.values()].reduce(
        (sum, managerProfileCount) =>
          sum +
          Math.min(managerProfileCount, Config.leoProManagedProfileMaxQty) *
            Config.leoProManagedProfilePrice,
        0
      ),
    [profileCountByManager]
  );

  useEffect(() => {
    void (async () => {
      setIsLoaded(false);

      const [_leoProOrganizationManagers, _leoProOrganizationProfiles] = await Promise.all([
        LeoProOrganizationManagersService.getLeoProOrganizationManagersForLeoProOrganization(
          leoProOrganizationId
        ),
        await LeoProOrganizationsService.getLeoProOrganizationProfilesForLeoProOrganization(
          leoProOrganizationId
        ),
      ]);

      setLeoProOrganizationManagers(_leoProOrganizationManagers);
      setLeoProOrganizationProfiles(_leoProOrganizationProfiles);
      setIsLoaded(true);
    })();
  }, [leoProOrganizationId]);

  if (!isLoaded) {
    return <LoadingSpinner />;
  }

  return (
    <Table>
      <thead>
        <tr>
          <th>Item</th>
          <th>Quantity</th>
          <th>Unit cost</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <strong>LEO-PRO organization</strong>
          </td>
          <td>1</td>
          <td>$0.00</td>
          <td>$0.00</td>
        </tr>
        {(leoProOrganizationManagers ?? []).map((leoProOrganizationManager) => {
          const numProfiles = profileCountByManager.get(leoProOrganizationManager) ?? 0;
          return (
            <>
              <tr>
                <td>
                  <strong>
                    LEO-PRO manager:{' '}
                    {leoProOrganizationManager?.user != null ? (
                      <UserName user={leoProOrganizationManager.user} />
                    ) : (
                      leoProOrganizationManager?.invite_email
                    )}
                  </strong>
                </td>
                <td>1</td>
                <td>$0.00</td>
                <td>$0.00</td>
              </tr>
              <tr>
                <td>
                  <span style={{ marginLeft: '15px' }}>
                    LEO-PRO managed profiles (1-{Config.leoProManagedProfileMaxQty})
                  </span>
                </td>
                <td>{Math.min(numProfiles, Config.leoProManagedProfileMaxQty)}</td>
                <td>${Config.leoProManagedProfilePrice}.00</td>
                <td>
                  {`$${(
                    Math.min(numProfiles, Config.leoProManagedProfileMaxQty) *
                    Config.leoProManagedProfilePrice
                  ).toFixed(2)}`}
                </td>
              </tr>
              <tr>
                <td>
                  <span style={{ marginLeft: '15px' }}>
                    LEO-PRO managed profiles (&gt; {Config.leoProManagedProfileMaxQty})
                  </span>
                </td>
                <td>{Math.max(numProfiles - Config.leoProManagedProfileMaxQty, 0)}</td>
                <td>$0.00</td>
                <td>$0.00</td>
              </tr>
            </>
          );
        })}
      </tbody>
      <tfoot>
        <tr>
          <th colSpan={3}>Total</th>
          <th>${totalCost.toFixed(2)}</th>
        </tr>
      </tfoot>
    </Table>
  );
};
