import React from 'react';
import { useParams } from 'react-router-dom';
import { LeoProPaymentAccountManager } from './leoProPaymentAccountManager';

export const ManageLeoProPaymentAccountRoute: React.FunctionComponent = () => {
  const { leoProOrganizationId } = useParams<{
    leoProOrganizationId: string;
  }>();

  return <LeoProPaymentAccountManager leoProOrganizationId={leoProOrganizationId} />;
};
