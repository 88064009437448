import React, { useCallback, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import { useProfileContext } from '@quiet-sunset/leo-shared';
import type { ProfileCertificationModel } from '@quiet-sunset/leo-shared';

import { LoadingSpinner } from '$cmp/loadingSpinner';

interface CertificationModalModel {
  profile_certification_model: ProfileCertificationModel;
  certified_accepted: boolean;
}

export const CertificationModal: React.FunctionComponent<{
  onClose: () => unknown;
}> = (props) => {
  const { onClose } = props;

  const { setProfileCertificationModel } = useProfileContext();
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);

  const submit = useCallback(
    async (values: CertificationModalModel) => {
      setShowLoadingSpinner(true);
      await setProfileCertificationModel(values.profile_certification_model);
      setShowLoadingSpinner(false);
      onClose();
    },
    [setProfileCertificationModel, onClose]
  );

  return (
    <Modal show backdrop="static" keyboard={false} size="lg">
      {showLoadingSpinner && <LoadingSpinner />}
      <Modal.Header>
        <Modal.Title>Certify LEO</Modal.Title>
      </Modal.Header>

      <Form<CertificationModalModel> onSubmit={submit}>
        {({ handleSubmit, values }) => (
          <form className="form-signin form-horizontal" onSubmit={handleSubmit}>
            <Modal.Body>
              <p>
                I,{' '}
                <Field
                  name="name"
                  component="input"
                  className="form-control form-control-inline"
                  placeholder="Name"
                  autoFocus
                />
                , also known as{' '}
                <Field
                  name="nickname"
                  component="input"
                  className="form-control form-control-inline"
                  placeholder="Nickname"
                />{' '}
                and{' '}
                <Field
                  name="other_name"
                  component="input"
                  className="form-control form-control-inline"
                  placeholder="Other name"
                />
                or formerly known as{' '}
                <Field
                  name="maiden_name"
                  component="input"
                  className="form-control form-control-inline"
                  placeholder="Maiden name"
                />
                , of{' '}
                <Field
                  name="county"
                  component="input"
                  className="form-control form-control-inline"
                  placeholder="County"
                />{' '}
                County, State of{' '}
                <Field
                  name="state"
                  component="input"
                  className="form-control form-control-inline"
                  placeholder="State"
                />
                , declare this to be my Life and Estate Organizer.
              </p>
              <p>
                My Life Estate Organizer is available in print as a PDF and Digital Format, contains
                all information represented in the Profile Scope Selection and includes all
                submitted input data to Life Estate Organizer.
              </p>
              <p>I hereby revoke all previous Life Estate Organizers or writings made by me.</p>
              <p>
                By means of my Executor and Secondary Executor appointment documents, uploaded to
                the Estate Settlement Section of my Life Estate Organizer, I have appointed{' '}
                <Field
                  name="executor_name"
                  component="input"
                  className="form-control form-control-inline"
                  placeholder="Executor name"
                />
                ,
                <Field
                  name="executor_address"
                  component="input"
                  className="form-control form-control-inline"
                  placeholder="Executor address"
                />{' '}
                to be Executor of my Estate.
              </p>
              <p>
                Should he/she be unwilling or unable to act as Executor, I nominate{' '}
                <Field
                  name="secondary_executor_name"
                  component="input"
                  className="form-control form-control-inline"
                  placeholder="Secondary executor name"
                />
                ,
                <Field
                  name="secondary_executor_address"
                  component="input"
                  className="form-control form-control-inline"
                  placeholder="Secondary executor address"
                />{' '}
                as my Secondary Executor.
              </p>
              <p>
                I hereby check this block, type in my name and the date of agreement that this is my
                Life Estate Organizer, has been filled out by me to the best of my knowledge and
                ability, revoke all previous Life Estate Organizers or writings made by me and
                direct my Executor to use my Life Estate Organizer in the settlement of my estate.
              </p>

              <div className="row">
                <label className="col-md-3 control-label">Full name</label>
                <div className="col-md-9">
                  <Field
                    name="profile_certification_model.signature_name"
                    component="input"
                    className="form-control"
                    required
                  />
                </div>
              </div>

              <div className="row">
                <label className="col-md-3 control-label">I accept</label>
                <div className="col-md-9">
                  <Field
                    name="certified_accepted"
                    component="input"
                    type="checkbox"
                    className="form-control"
                    required
                  />
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                disabled={
                  !values.certified_accepted ||
                  values.profile_certification_model?.signature_name == null ||
                  !values.profile_certification_model?.signature_name.length
                }
              >
                Submit
              </Button>
              <Button variant="default" onClick={onClose}>
                Close
              </Button>
            </Modal.Footer>
          </form>
        )}
      </Form>
    </Modal>
  );
};
