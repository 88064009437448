import React from 'react';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { useAsyncMemo, useMenuItemsService } from '@quiet-sunset/leo-shared';

import { Card } from '$cmp/card';

const StyledCard = styled(Card)`
  height: 250px;
  width: 250px;
`;

const CardContents = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ProfileScopeSelectionRootMenuItemsRoute: React.FunctionComponent = () => {
  const MenuItemsService = useMenuItemsService();

  const { url: baseRoute } = useRouteMatch();

  const [rootMenuItems] = useAsyncMemo(
    () => MenuItemsService.getMenuItemsByParentId(null),
    [MenuItemsService]
  );

  return (
    <>
      <h1 style={{ textAlign: 'center' }}>LEO Sections</h1>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: '15px',
        }}
      >
        {(rootMenuItems ?? []).map((menuItem) => (
          <Link key={menuItem.id} to={`${baseRoute}/${menuItem.id}`} style={{ color: '#000' }}>
            <StyledCard key={menuItem.id}>
              <CardContents>
                <h4 style={{ textAlign: 'center' }}>{menuItem.label}</h4>
                {menuItem.icon_html && (
                  <div
                    style={{ flex: '1', overflow: 'hidden', padding: '20px' }}
                    /* eslint-disable-next-line react/no-danger */
                    dangerouslySetInnerHTML={{ __html: menuItem.icon_html }}
                  />
                )}
              </CardContents>
            </StyledCard>
          </Link>
        ))}
      </div>
    </>
  );
};
