var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import Button from '@mui/material/Button';
import { useSignInWithRedirect, GoogleAuthProvider } from '@sprice237/react-auth';
const StyledButton = styled(Button) `
  width: 250px;
  height: 50px;
  display: flex;
  flex-direction: row;
`;
const IconWrapper = styled.div `
  margin: 5px 10px 5px 0;

  height: 24px;
  width: 24px;

  & img {
    height: 100%;
    width: 100%;
  }
`;
export const GoogleLoginButton = ({ onClick, onSuccess, onError }) => {
    const signInWithRedirect = useSignInWithRedirect();
    const handleClick = () => __awaiter(void 0, void 0, void 0, function* () {
        onClick === null || onClick === void 0 ? void 0 : onClick.call(null);
        try {
            const provider = new GoogleAuthProvider();
            const result = yield signInWithRedirect(provider);
            onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess.call(null, result);
        }
        catch (error) {
            onError === null || onError === void 0 ? void 0 : onError.call(null, error);
        }
    });
    return (_jsxs(StyledButton, Object.assign({ variant: "outlined", onClick: handleClick }, { children: [_jsx(IconWrapper, { children: _jsx("img", { src: "https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" }, void 0) }, void 0), _jsx("div", { children: "Sign in with Google" }, void 0)] }), void 0));
};
