import React from 'react';

import { FieldGroupItem } from '@quiet-sunset/leo-shared';

// eslint-disable-next-line import/no-cycle
import { ProfileContentFieldGroup } from '../profileContentFieldGroup';
import { ProfileContentField } from '../../fields/profileContentField';
import { FieldTitle } from '../fieldTitle';
import { FieldGroupTitle } from '../fieldGroupTitle';
import { ProfileContentFieldGroupItemDependencyChecker } from '../profileContentFieldGroupItemDependencyChecker';

export interface ProfileContentFieldGroupVerticalFieldGroupItemProps {
  fieldGroupItem: FieldGroupItem;
  profileFieldGroupListItemId: string | null;
}

export const ProfileContentFieldGroupVerticalFieldGroupItem: React.FunctionComponent<ProfileContentFieldGroupVerticalFieldGroupItemProps> =
  (props) => {
    const { fieldGroupItem, profileFieldGroupListItemId } = props;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const launchHelpTextDialog = () => {};

    return (
      <ProfileContentFieldGroupItemDependencyChecker
        fieldGroupItem={fieldGroupItem}
        profileFieldGroupListItemId={profileFieldGroupListItemId}
      >
        <div style={{ margin: '30px 30px' }}>
          <div style={{ fontWeight: 'bold' }}>
            {fieldGroupItem.field_id != null && (
              <FieldTitle
                fieldId={fieldGroupItem.field_id}
                launchHelpTextDialog={launchHelpTextDialog}
              />
            )}
            {fieldGroupItem.field_group_id != null && (
              <FieldGroupTitle
                fieldGroupId={fieldGroupItem.field_group_id}
                launchHelpTextDialog={launchHelpTextDialog}
              />
            )}
          </div>
          <div>
            {fieldGroupItem.field_id != null && (
              <ProfileContentField
                fieldId={fieldGroupItem.field_id}
                profileFieldGroupListItemId={profileFieldGroupListItemId}
              />
            )}
            {fieldGroupItem.field_group_id != null && (
              <ProfileContentFieldGroup
                fieldGroupId={fieldGroupItem.field_group_id}
                isListChildItem={false}
                profileFieldGroupListItemId={profileFieldGroupListItemId}
              />
            )}
          </div>
        </div>
      </ProfileContentFieldGroupItemDependencyChecker>
    );
  };
