import React from 'react';
// eslint-disable-next-line import/no-cycle
import { ProfileContentFieldGroupVertical } from './vertical/profileContentFieldGroupVertical';
// eslint-disable-next-line import/no-cycle
import { ProfileContentFieldGroupVerticalListTabbed } from './vertical/profileContentFieldGroupVerticalListTabbed';
// eslint-disable-next-line import/no-cycle
import { ProfileContentFieldGroupHorizontal } from './horizontal/profileContentFieldGroupHorizontal';
// // eslint-disable-next-line import/no-cycle
// import { ProfileContentFieldGroupVerticalListStacked } from './verticalStacked/profileContentFieldGroupVerticalListStacked';
// // eslint-disable-next-line import/no-cycle
// import { ProfileContentFieldGroupSectioned } from './profileContentFieldGroupSectioned';
import {
  useFieldGroup,
  useProfilePageContext,
  useProfilePageMenuItemContext,
} from '@quiet-sunset/leo-shared';
import { ProfileContentListSlider } from '../slider/profileContentListSlider';
import Button from '@mui/material/Button';

export interface ProfileContentFieldGroupProps {
  fieldGroupId: string;
  isListChildItem: boolean;
  profileFieldGroupListItemId: string | null;
}

export const ProfileContentFieldGroup: React.FunctionComponent<ProfileContentFieldGroupProps> = ({
  fieldGroupId,
  isListChildItem,
  profileFieldGroupListItemId,
}) => {
  const { push } = useProfilePageMenuItemContext();
  const { rootFieldGroupId } = useProfilePageContext();

  const profileFieldGroup = useFieldGroup(fieldGroupId);

  if (!profileFieldGroup) {
    return null;
  }

  if (
    fieldGroupId === rootFieldGroupId &&
    profileFieldGroup.is_list &&
    profileFieldGroup.display_as_slider &&
    !isListChildItem
  ) {
    return (
      <ProfileContentListSlider
        fieldGroupId={fieldGroupId}
        parentProfileFieldGroupListItemId={profileFieldGroupListItemId}
      />
    );
  }

  const displayType = isListChildItem ? 'vertical' : profileFieldGroup.display_type;

  if (fieldGroupId !== rootFieldGroupId && profileFieldGroup.display_as_slider) {
    return (
      <Button
        variant="contained"
        onClick={() =>
          push({
            fieldGroupId,
            isListChildItem: false,
            profileFieldGroupListItemId,
            label: profileFieldGroup.label,
          })
        }
      >
        Open &gt;
      </Button>
    );
  }

  switch (displayType) {
    case 'vertical':
      return (
        <ProfileContentFieldGroupVertical
          fieldGroupId={fieldGroupId}
          profileFieldGroupListItemId={profileFieldGroupListItemId}
        />
      );

    case 'vertical_tabbed':
      return (
        <ProfileContentFieldGroupVerticalListTabbed
          fieldGroupId={fieldGroupId}
          profileFieldGroupListItemId={profileFieldGroupListItemId}
        />
      );
    case 'horizontal':
      return (
        <ProfileContentFieldGroupHorizontal
          fieldGroupId={fieldGroupId}
          profileFieldGroupListItemId={profileFieldGroupListItemId}
        />
      );
    case 'vertical_stacked':
      return (
        // <ProfileContentFieldGroupVerticalListStacked
        //   fieldGroupId={fieldGroupId}
        //   profileFieldGroupListItemId={profileFieldGroupListItemId}
        // />
        <p>ProfileContentFieldGroupVerticalListStacked</p>
      );
    case 'sectioned':
      return (
        // <ProfileContentFieldGroupSectioned
        //   fieldGroupId={fieldGroupId}
        //   profileFieldGroupListItemId={profileFieldGroupListItemId}
        // />
        <p>ProfileContentFieldGroupSectioned</p>
      );
    default:
      return <p>Unknown field group display type {profileFieldGroup.display_type}</p>;
  }
};
