import React, { useCallback } from 'react';
import { v4 as uuid } from 'uuid';

import type { ProfileFieldValue, ProfileFieldMultiFileValue } from '@quiet-sunset/leo-shared';

import {
  convertFromEditTrustedUserFieldType,
  isEditTrustedUserFieldType,
  useProfileFieldValuesService,
  useField,
  useProfileFieldValue,
  useProfilePageContext,
} from '@quiet-sunset/leo-shared';

import { DateField } from './dateField';
import { FilesField } from './fileField';
import { HtmlField } from './htmlField';
import { HtmlDisplayField } from './htmlDisplayField';
import { NumericField } from './numericField';
import { ParagraphField } from './paragraphField';
import { PasswordField } from './passwordField';
import { PetGuardianAgreementField } from './petGuardianAgreementField';
import { SingleSelectField } from './singleSelectField';
import { TextField } from './textField';
import { TrustedUserField } from './trustedUserField';
import { SingleSelectFieldGroupField } from './singleSelectFieldGroupField';

export interface ProfileContentFieldProps {
  fieldId: string;
  profileFieldGroupListItemId: string | null;
}

export const ProfileContentField: React.FunctionComponent<ProfileContentFieldProps> = (props) => {
  const { fieldId, profileFieldGroupListItemId } = props;

  const ProfileFieldValuesService = useProfileFieldValuesService();

  const field = useField(fieldId);

  const { disableInputs } = useProfilePageContext();
  const [profileFieldValue, setProfileFieldValue, isDirty] = useProfileFieldValue(
    fieldId,
    profileFieldGroupListItemId
  );

  const updateProfileFieldValue = useCallback(
    (updateModel: Partial<ProfileFieldValue>) => {
      const newProfileFieldValue: ProfileFieldValue = {
        ...(profileFieldValue ??
          ({
            id: uuid(),
            profile_field_group_list_item_id: profileFieldGroupListItemId,
            field_id: fieldId,
          } as ProfileFieldValue)),
        ...updateModel,
      };
      setProfileFieldValue(newProfileFieldValue);
    },
    [fieldId, profileFieldGroupListItemId, profileFieldValue]
  );

  const onTextValueChanged = useCallback(
    (newValue: string) => {
      updateProfileFieldValue({
        text_value: newValue,
      });
    },
    [updateProfileFieldValue]
  );

  const onNumericValueChanged = useCallback((newValue: number | null) => {
    updateProfileFieldValue({
      numeric_value: newValue ?? undefined,
    });
  }, []);

  const onDecimalValueChanged = useCallback((newValue: number | null) => {
    updateProfileFieldValue({
      decimal_value: newValue ?? undefined,
    });
  }, []);

  const onDateValueChanged = useCallback(
    (newValue: string | null) => {
      updateProfileFieldValue({
        date_value: newValue ?? undefined,
      });
    },
    [updateProfileFieldValue]
  );

  const onSingleSelectValueChanged = useCallback(
    (newValue: string | null) => {
      updateProfileFieldValue({
        single_select_option_id: newValue,
      });
    },
    [updateProfileFieldValue]
  );

  const onSingleSelectFieldGroupValueChanged = useCallback(
    (newValue: string | null) => {
      updateProfileFieldValue({
        single_select_profile_field_group_list_item_id: newValue,
      });
    },
    [updateProfileFieldValue]
  );

  const onExistingFileValuesChange = useCallback(
    (fileValues: ProfileFieldMultiFileValue[]) => {
      updateProfileFieldValue({
        profile_field_multi_file_values: fileValues,
      });
    },
    [updateProfileFieldValue]
  );

  const onNewFilesChange = useCallback(
    async (files: File[]) => {
      const filesDetails = await ProfileFieldValuesService.uploadFiles(files);

      const existingProfileFieldMultiFileValues =
        profileFieldValue?.profile_field_multi_file_values ?? [];

      const newProfileFieldMultiFileValues = filesDetails.map(
        ({ fileDetails, fileDownloadUrl }, index) =>
          ({
            id: uuid(),
            profile_field_value_id: fieldId,
            file_id: fileDetails.id,
            order_index: existingProfileFieldMultiFileValues.length + index,
            file_details: fileDetails,
            file_download_url: fileDownloadUrl,
          } as ProfileFieldMultiFileValue)
      );

      updateProfileFieldValue({
        profile_field_multi_file_values: [
          ...existingProfileFieldMultiFileValues,
          ...newProfileFieldMultiFileValues,
        ],
      });
    },
    [fieldId, profileFieldValue, updateProfileFieldValue]
  );

  if (field == null) {
    return null;
  }

  if (field.field_type === 'text') {
    return (
      <TextField
        value={profileFieldValue?.text_value ?? ''}
        onChange={onTextValueChanged}
        isDirty={isDirty}
        disabled={disableInputs}
      />
    );
  }
  if (field.field_type === 'password') {
    return (
      <PasswordField
        value={profileFieldValue?.text_value ?? ''}
        onChange={onTextValueChanged}
        isDirty={isDirty}
        disabled={disableInputs}
      />
    );
  }
  if (field.field_type === 'paragraph') {
    return (
      <ParagraphField
        value={profileFieldValue?.text_value ?? ''}
        onChange={onTextValueChanged}
        isDirty={isDirty}
        disabled={disableInputs}
      />
    );
  }
  if (field.field_type === 'html') {
    return (
      <HtmlField
        value={profileFieldValue?.text_value ?? ''}
        onChange={onTextValueChanged}
        isDirty={isDirty}
        disabled={disableInputs}
      />
    );
  }
  if (field.field_type === 'numeric') {
    return (
      <NumericField
        value={profileFieldValue?.numeric_value ?? null}
        onChange={onNumericValueChanged}
        isDirty={isDirty}
        disabled={disableInputs}
      />
    );
  }
  if (field.field_type === 'decimal') {
    return (
      <NumericField
        value={profileFieldValue?.decimal_value ?? null}
        onChange={onDecimalValueChanged}
        isDirty={isDirty}
        disabled={disableInputs}
      />
    );
  }
  if (field.field_type === 'date') {
    return (
      <DateField
        value={(profileFieldValue?.date_value as string | null) ?? null}
        onChange={onDateValueChanged}
        isDirty={isDirty}
        disabled={disableInputs}
      />
    );
  }
  if (field.field_type === 'single_select' && field.field_option_list_id) {
    return (
      <SingleSelectField
        fieldOptionListId={field.field_option_list_id}
        value={profileFieldValue?.single_select_option_id ?? null}
        onChange={onSingleSelectValueChanged}
        isDirty={isDirty}
        disabled={disableInputs}
      />
    );
  }
  if (field.field_type === 'single_select_field_group' && field.single_select_field_group_id) {
    return (
      <SingleSelectFieldGroupField
        fieldGroupId={field.single_select_field_group_id}
        value={profileFieldValue?.single_select_profile_field_group_list_item_id ?? null}
        onChange={onSingleSelectFieldGroupValueChanged}
        isDirty={isDirty}
        disabled={disableInputs}
      />
    );
  }
  if (field.field_type === 'edit_pet_guardian_agreement') {
    return (
      <PetGuardianAgreementField
        fieldId={field.id}
        profileFieldGroupListItemId={profileFieldGroupListItemId}
      />
    );
  }
  if (field.field_type === 'file' || field.field_type === 'multi_file') {
    return (
      <FilesField
        existingFileValues={profileFieldValue?.profile_field_multi_file_values ?? []}
        onExistingFileValuesChange={onExistingFileValuesChange}
        onFilesSelected={onNewFilesChange}
        multiple={field.field_type === 'multi_file'}
        isDirty={isDirty}
        disabled={disableInputs}
      />
    );
  }
  if (field.field_type === 'html_display') {
    return <HtmlDisplayField field={field} />;
  }
  if (isEditTrustedUserFieldType(field.field_type)) {
    return (
      <TrustedUserField
        trustedUserType={convertFromEditTrustedUserFieldType(field.field_type)}
        profileFieldGroupListItemId={profileFieldGroupListItemId}
      />
    );
  }
  return <p>Unknown field type {field.field_type}</p>;
};
