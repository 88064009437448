var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext, useEffect, useState, useRef, useCallback } from 'react';
import { useProfileContext } from "../profileContext";
import { useFieldGroupsService, useProfileFieldGroupListItemsService, useProfileFieldValuesService, useProfilesService, } from "../../services";
import { EventTarget } from "../../utils";
import { ProfileFieldValuesMap } from './profileFieldValuesMap';
import { ProfileFieldGroupListItemsMap } from './profileFieldGroupListItemsMap';
import { ProfileFieldGroupListItemsReloadEvent } from './events';
import { useProfilePageMenuItemContext } from './profilePageMenuItemContext';
// the default value for the context is meaningless because the context itself isn't exported
// so the only way to construct one is by using the `<ProfilePageContextProvider />` component
// which creates its own value
const ProfilePageContext = React.createContext(null);
ProfilePageContext.displayName = 'ProfilePageContext';
export const useProfilePageContext = () => useContext(ProfilePageContext);
export const ProfilePageContextProvider = ({ rootFieldGroupId, rootProfileFieldGroupListItemId, isListChildItem, Loading, onLoad, children, }) => {
    const FieldGroupsService = useFieldGroupsService();
    const ProfilesService = useProfilesService();
    const ProfileFieldValuesService = useProfileFieldValuesService();
    const ProfileFieldGroupListItemsService = useProfileFieldGroupListItemsService();
    const { profileId } = useProfileContext();
    const { menuItemId } = useProfilePageMenuItemContext();
    const [isInitialized, setIsInitialized] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [lastSaveDate, setLastSaveDate] = useState(() => new Date());
    const [rootFieldGroup, setRootFieldGroup] = useState();
    const [hasProfileCertificationModel, setHasProfileCertificationModel] = useState();
    const [hasUnrestrictedAccess, setHasUnrestrictedAccess] = useState();
    const [hasAccess, setHasAccess] = useState();
    const [disableInputs, setDisableInputs] = useState();
    const [disableSaving, setDisableSaving] = useState();
    const profileFieldValuesMapRef = useRef();
    const profileFieldGroupListItemsMapRef = useRef();
    const { current: eventTarget } = useRef(new EventTarget());
    const reloadProfileFieldGroupListItems = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        profileFieldGroupListItemsMapRef.current =
            yield ProfileFieldGroupListItemsMap.initializeFromService(ProfileFieldGroupListItemsService, profileId, rootFieldGroupId);
        [...profileFieldGroupListItemsMapRef.current.keys()].forEach((key) => {
            const { fieldGroupId, parentProfileFieldGroupListItemId } = ProfileFieldGroupListItemsMap.decomposeKeyString(key);
            eventTarget.dispatchEvent(new ProfileFieldGroupListItemsReloadEvent({
                fieldGroupId,
                parentProfileFieldGroupListItemId,
            }));
        });
    }), [ProfileFieldGroupListItemsService, profileId, rootFieldGroupId]);
    useEffect(() => {
        setIsInitialized(false);
        const loadRootFieldGroup = () => __awaiter(void 0, void 0, void 0, function* () {
            setRootFieldGroup(undefined);
            const _rootFieldGroup = yield FieldGroupsService.getFieldGroupWithNestedFields(rootFieldGroupId);
            setRootFieldGroup(_rootFieldGroup);
        });
        (() => __awaiter(void 0, void 0, void 0, function* () {
            yield loadRootFieldGroup();
            profileFieldValuesMapRef.current = yield ProfileFieldValuesMap.initializeFromService(ProfileFieldValuesService, profileId, rootFieldGroupId);
            yield reloadProfileFieldGroupListItems();
            const _hasProfileCertificationModel = yield ProfilesService.getHasProfileCertificationModelByProfileId(profileId);
            setHasProfileCertificationModel(_hasProfileCertificationModel);
            const { has_unrestricted_access, has_access, can_edit } = yield ProfilesService.getMenuItemAccessForProfileAndMenuItem(profileId, menuItemId);
            setHasUnrestrictedAccess(has_unrestricted_access);
            setHasAccess(has_access);
            setDisableInputs(hasProfileCertificationModel || !can_edit);
            setDisableSaving(hasProfileCertificationModel || !can_edit);
            setIsInitialized(true);
            onLoad === null || onLoad === void 0 ? void 0 : onLoad.call(null);
        }))();
    }, [profileId, menuItemId, rootFieldGroupId, reloadProfileFieldGroupListItems]);
    if (!isInitialized ||
        !rootFieldGroup ||
        !profileFieldValuesMapRef.current ||
        !profileFieldGroupListItemsMapRef.current ||
        hasUnrestrictedAccess === undefined ||
        hasAccess === undefined ||
        disableInputs === undefined ||
        disableSaving === undefined) {
        return _jsx(Loading, {}, void 0);
    }
    const contextValue = {
        rootFieldGroupId,
        rootProfileFieldGroupListItemId,
        isListChildItem,
        lastSaveDate,
        setLastSaveDate,
        isSaving,
        setIsSaving,
        rootFieldGroup,
        eventTarget,
        profileFieldValuesMapRef: profileFieldValuesMapRef,
        profileFieldGroupListItemsMapRef: profileFieldGroupListItemsMapRef,
        hasUnrestrictedAccess,
        hasAccess,
        disableInputs,
        disableSaving,
        reloadProfileFieldGroupListItems,
    };
    return _jsx(ProfilePageContext.Provider, Object.assign({ value: contextValue }, { children: children }), void 0);
};
export const ProfilePageContextConsumer = ProfilePageContext.Consumer;
