import { useCallback } from 'react';
import {
  FieldGroup,
  FieldGroupItem,
  useFieldsService,
  useFieldGroupsService,
  useFieldGroupItemsService,
} from '@quiet-sunset/leo-shared';
import { useProfileAdminClipboardContext } from '../profileAdminClipboardContext';

export const useRetrieveFieldGroupItems = (
  fieldGroupId: string,
  setFieldGroupItems: React.Dispatch<React.SetStateAction<FieldGroupItem[]>>
) => {
  const FieldGroupItemsService = useFieldGroupItemsService();

  return useCallback(async () => {
    const fieldGroupItems = await FieldGroupItemsService.getFieldGroupItemsForFieldGroup(
      fieldGroupId
    );
    setFieldGroupItems(fieldGroupItems);
  }, [FieldGroupItemsService, setFieldGroupItems, fieldGroupId]);
};

export const useRetrieveFieldGroup = (
  fieldGroupId: string,
  setFieldGroup: React.Dispatch<React.SetStateAction<FieldGroup | undefined>>
) => {
  const FieldGroupsService = useFieldGroupsService();

  return useCallback(async () => {
    const fieldGroup = await FieldGroupsService.getFieldGroup(fieldGroupId);
    setFieldGroup(fieldGroup);
  }, [FieldGroupsService, setFieldGroup, fieldGroupId]);
};

export const useUpdateFieldGroup = (onComplete: () => void) => {
  const FieldGroupsService = useFieldGroupsService();

  return useCallback(
    async (updateModel: FieldGroup) => {
      await FieldGroupsService.updateFieldGroup(updateModel);
      onComplete();
    },
    [FieldGroupsService, onComplete]
  );
};

export const useDeleteFieldGroup = (fieldGroupId: string, onComplete: () => void) => {
  const FieldGroupsService = useFieldGroupsService();

  return useCallback(async () => {
    await FieldGroupsService.deleteFieldGroup(fieldGroupId);
    onComplete();
  }, [FieldGroupsService, fieldGroupId, onComplete]);
};

export const useCopyFieldGroup = (
  fieldGroup: FieldGroup | undefined,
  fieldGroupItemId: string | null,
  onCopyComplete?: () => void
) => {
  const profileAdminContext = useProfileAdminClipboardContext();

  return useCallback(() => {
    if (!fieldGroup) {
      return;
    }
    profileAdminContext.addToClipboard({
      type: 'field_group',
      fieldGroupItemId,
      fieldGroup,
      onCopyComplete,
    });
  }, [fieldGroup, fieldGroupItemId, profileAdminContext, onCopyComplete]);
};

export const useAddFieldGroup = (parentFieldGroupId: string, onComplete: () => void) => {
  const FieldGroupsService = useFieldGroupsService();

  return useCallback(async () => {
    const newFieldGroup = await FieldGroupsService.createFieldGroup({
      name: '',
      label: 'New field group',
      display_type: 'vertical',
      is_list: false,
      default_values: null,
      help_text: null,
      field_group_items: [],
      field_group_column_widths: [],
      display_as_slider: false,
    });

    await FieldGroupsService.moveFieldGroupItemsToFieldGroup(parentFieldGroupId, 'move', [
      { field_group_id: newFieldGroup.id },
    ]);

    onComplete();
  }, [FieldGroupsService, parentFieldGroupId, onComplete]);
};

export const useAddField = (parentFieldGroupId: string, onComplete: () => void) => {
  const FieldsService = useFieldsService();
  const FieldGroupsService = useFieldGroupsService();

  return useCallback(async () => {
    const newField = await FieldsService.createField({
      name: '',
      label: 'New field',
      field_type: 'text',
      field_option_list_id: null,
      single_select_field_group_id: null,
      help_text: null,
    });

    await FieldGroupsService.moveFieldGroupItemsToFieldGroup(parentFieldGroupId, 'move', [
      { field_id: newField.id },
    ]);

    onComplete();
  }, [FieldsService, FieldGroupsService, parentFieldGroupId, onComplete]);
};

export const useAddFieldGroupItemsFromClipboard = (
  rootFieldGroupId: string,
  onComplete: () => void
) => {
  const { pasteFieldGroupItemsToFieldGroup } = useProfileAdminClipboardContext();

  return useCallback(async () => {
    await pasteFieldGroupItemsToFieldGroup(rootFieldGroupId);
    onComplete();
  }, [pasteFieldGroupItemsToFieldGroup, rootFieldGroupId, onComplete]);
};
