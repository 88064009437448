import React, { useCallback, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useProfileContext } from '@quiet-sunset/leo-shared';

import { LoadingSpinner } from '$cmp/loadingSpinner';

export const CertificationUnlockModal: React.FunctionComponent<{
  onClose: () => unknown;
}> = (props) => {
  const { clearProfileCertificationModel } = useProfileContext();
  const { onClose } = props;

  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);

  const submit = useCallback(async () => {
    setShowLoadingSpinner(true);
    await clearProfileCertificationModel();
    setShowLoadingSpinner(false);
    onClose();
  }, [onClose, clearProfileCertificationModel]);

  return (
    <Modal show backdrop="static" keyboard={false}>
      {showLoadingSpinner && <LoadingSpinner />}
      <Modal.Header>
        <Modal.Title>Unlock LEO</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Unlocking LEO will remove the certification and allow edits. Proceed?</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={submit}>
          Yes
        </Button>
        <Button variant="default" onClick={onClose}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
