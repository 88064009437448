import React from 'react';
import styled from 'styled-components';

const ProfileContentViewportFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const ProfileContentViewportContentContainer = styled.div`
  width: 100%;
  max-width: 1000px;
`;

export const ProfileContentViewport: React.FC = ({ children }) => (
  <ProfileContentViewportFlexContainer>
    <ProfileContentViewportContentContainer>{children}</ProfileContentViewportContentContainer>
  </ProfileContentViewportFlexContainer>
);
