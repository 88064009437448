import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { useProfileContext } from '@quiet-sunset/leo-shared';

import { ProfileScopeSelectionRoute } from './profileScopeSelection/profileScopeSelectionRoute';
import { ProfileSplashPageRoute } from './profileSplashPageRoute';
import { ProfileContentMenuItemContainerRoute } from './profileContent';
import { ProfileManageLeoProAccessRoute } from './profileManageLeoProAccessRoute';

export const ProfileContentRouter: React.FunctionComponent = () => {
  const { baseRoute } = useProfileContext();
  return (
    <Switch>
      <Route exact path={`${baseRoute}`} component={ProfileSplashPageRoute} />
      <Route
        exact
        path={`${baseRoute}/leo-pro-access`}
        component={ProfileManageLeoProAccessRoute}
      />
      <Route path={`${baseRoute}/sections`} component={ProfileScopeSelectionRoute} />
      <Route path={`${baseRoute}/:menuItemId`} component={ProfileContentMenuItemContainerRoute} />
      <Redirect to={baseRoute} />
    </Switch>
  );
};
