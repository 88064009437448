var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback } from 'react';
import { useProfileContext } from "../profileContext";
import { useFieldGroupItemsService, useFieldGroupsService, useMenuItemsService, useProfileFieldGroupListItemsService, } from "../../services";
export const useGetNavigatorItemForFieldGroupListItem = () => {
    const FieldGroupsService = useFieldGroupsService();
    const { profileId } = useProfileContext();
    return useCallback((menuItemId, fieldGroupId, profileFieldGroupListItemId, label) => {
        return {
            type: 'NavigationButton',
            navigatorState: {
                type: 'FieldGroup',
                isList: false,
                menuItemId,
                fieldGroupId,
                profileFieldGroupListItemId,
            },
            label,
        };
    }, [FieldGroupsService, profileId]);
};
export const useGetNavigatorItemForFieldGroup = () => {
    const FieldGroupsService = useFieldGroupsService();
    const { baseRoute: profileBaseRoute, profileId } = useProfileContext();
    return useCallback((menuItemId, fieldGroupId, profileFieldGroupListItemId) => __awaiter(void 0, void 0, void 0, function* () {
        const fieldGroup = yield FieldGroupsService.getFieldGroup(fieldGroupId);
        const baseRoute = `${profileBaseRoute}/${menuItemId}/${fieldGroupId}`;
        const searchParams = new URLSearchParams();
        let hasSearchParams = false;
        if (profileFieldGroupListItemId) {
            hasSearchParams = true;
            searchParams.set('listItemId', profileFieldGroupListItemId);
        }
        return {
            type: 'NavigationLink',
            to: `${baseRoute}${hasSearchParams ? '?' : ''}${searchParams}`,
            label: fieldGroup.label,
        };
    }), [FieldGroupsService, profileBaseRoute, profileId]);
};
export const useGetNavigatorItemForMenuItem = () => {
    const getNavigatorItemForFieldGroup = useGetNavigatorItemForFieldGroup();
    return useCallback((menuItem) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        if (menuItem.has_children) {
            return {
                type: 'NavigationButton',
                navigatorState: {
                    type: 'MenuItem',
                    menuItemId: menuItem.id,
                },
                iconHtml: (_a = menuItem.icon_html) !== null && _a !== void 0 ? _a : undefined,
                label: menuItem.label,
            };
        }
        if (menuItem.field_group_id) {
            return yield getNavigatorItemForFieldGroup(menuItem.id, menuItem.field_group_id, null);
        }
        return {
            type: 'Text',
            label: menuItem.label,
        };
    }), [getNavigatorItemForFieldGroup]);
};
export const useGetNavigatorItemsForMenuItemNavigatorStateDescriptor = () => {
    const MenuItemsService = useMenuItemsService();
    const getNavigatorItemForMenuItem = useGetNavigatorItemForMenuItem();
    const { profileId } = useProfileContext();
    return useCallback((navigatorState) => __awaiter(void 0, void 0, void 0, function* () {
        const { menuItemId } = navigatorState;
        const menuItems = yield MenuItemsService.getMenuItemsByParentIdForProfile(profileId, menuItemId);
        const navigationItems = yield Promise.all(menuItems.map(getNavigatorItemForMenuItem));
        return navigationItems;
    }), [MenuItemsService, getNavigatorItemForMenuItem, profileId]);
};
export const useGetNavigatorItemsForListFieldGroupNavigatorStateDescriptor = () => {
    const ProfileFieldGroupListItemsService = useProfileFieldGroupListItemsService();
    const getNavigatorItemForFieldGroupListItem = useGetNavigatorItemForFieldGroupListItem();
    const { baseRoute, profileId } = useProfileContext();
    return useCallback((navigatorState) => __awaiter(void 0, void 0, void 0, function* () {
        const { menuItemId, fieldGroupId, profileFieldGroupListItemId } = navigatorState;
        const managementLinkBaseRoute = `${baseRoute}/${menuItemId}/${fieldGroupId}/manage-list`;
        const managementLinkSearchParams = new URLSearchParams();
        let managementLinkHasSearchParams = false;
        if (profileFieldGroupListItemId) {
            managementLinkHasSearchParams = true;
            managementLinkSearchParams.set('listItemId', profileFieldGroupListItemId);
        }
        const managementLinkNavigationItem = {
            type: 'NavigationLink',
            to: `${managementLinkBaseRoute}${managementLinkHasSearchParams ? '?' : ''}${managementLinkSearchParams}`,
            label: 'Manage list',
        };
        const profileFieldGroupListItems = yield ProfileFieldGroupListItemsService.getAllForProfile(profileId, fieldGroupId);
        const navigationItems = [
            ...profileFieldGroupListItems.map((profileFieldGroupListItem) => {
                var _a;
                return getNavigatorItemForFieldGroupListItem(menuItemId, fieldGroupId, profileFieldGroupListItem.id, (_a = profileFieldGroupListItem.title) !== null && _a !== void 0 ? _a : '');
            }),
            managementLinkNavigationItem,
        ];
        return navigationItems;
    }), [ProfileFieldGroupListItemsService, getNavigatorItemForFieldGroupListItem, baseRoute, profileId]);
};
export const useGetNavigatorItemsForNonListFieldGroupNavigatorStateDescriptor = () => {
    const FieldGroupItemsService = useFieldGroupItemsService();
    const getNavigatorItemForFieldGroup = useGetNavigatorItemForFieldGroup();
    return useCallback((navigatorState) => __awaiter(void 0, void 0, void 0, function* () {
        const { menuItemId, fieldGroupId, profileFieldGroupListItemId } = navigatorState;
        const fieldGroupItems = yield FieldGroupItemsService.getFieldGroupItemsForFieldGroup(fieldGroupId);
        const childFieldGroups = fieldGroupItems
            .filter((fieldGroupItem) => fieldGroupItem.field_group)
            .map((fieldGroupItem) => fieldGroupItem.field_group);
        const navigationItems = yield Promise.all(childFieldGroups.map((childFieldGroup) => getNavigatorItemForFieldGroup(menuItemId, childFieldGroup.id, profileFieldGroupListItemId)));
        return navigationItems;
    }), [FieldGroupItemsService, getNavigatorItemForFieldGroup]);
};
