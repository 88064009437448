import React from 'react';
import { useParams } from 'react-router';
import { Switch, Redirect, Route } from 'react-router-dom';
import {
  ProfilePageMenuItemContextProvider,
  useProfileContext,
  useProfilePageMenuItemContext,
  useAsyncMemo,
  useFieldGroupsService,
} from '@quiet-sunset/leo-shared';
import { HelpTextButton } from '$cmp/helpTextButton/helpTextButton';
import { LoadingSpinner } from '$cmp/loadingSpinner';
import { ProfilePageStackBreadcrumbs } from './profilePageStackBreadcrumbs';
import { ProfileContentViewport } from '../profileContentViewport';
import { ProfileContentSlider } from './profileContentSlider';

const InnerProfileContentRoute: React.FunctionComponent = () => {
  const { menuItem, currentPageStackItem } = useProfilePageMenuItemContext();

  const FieldGroupsService = useFieldGroupsService();

  const [fieldGroup] = useAsyncMemo(
    () => FieldGroupsService.getFieldGroup(currentPageStackItem.fieldGroupId),
    [currentPageStackItem]
  );
  const [menuItemFieldGroup] = useAsyncMemo(
    async () =>
      menuItem.field_group_id
        ? await FieldGroupsService.getFieldGroup(menuItem.field_group_id)
        : undefined,
    [currentPageStackItem]
  );

  if (!fieldGroup || !menuItemFieldGroup) {
    return null;
  }

  return (
    <ProfileContentViewport>
      <h3>
        {menuItem.label}
        {menuItemFieldGroup.help_text != null && (
          <HelpTextButton
            title={menuItemFieldGroup.label}
            helpText={menuItemFieldGroup.help_text}
          />
        )}
      </h3>
      <ProfilePageStackBreadcrumbs />
      <ProfileContentSlider />
    </ProfileContentViewport>
  );
};

export const ProfileContentMenuItemContainerRoute: React.FunctionComponent = () => {
  const { baseRoute } = useProfileContext();
  const { menuItemId } = useParams<{ menuItemId: string }>();

  return (
    <ProfilePageMenuItemContextProvider
      key={menuItemId}
      menuItemId={menuItemId}
      Loading={LoadingSpinner}
    >
      <Switch>
        <Route
          path={`${baseRoute}/${menuItemId}/:fieldGroupId`}
          component={InnerProfileContentRoute}
        />
        <Redirect to="." />
      </Switch>
    </ProfilePageMenuItemContextProvider>
  );
};
