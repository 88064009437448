import React, { VFC } from 'react';

import leoLogo from '$assets/leo-logo.png';
import leoLogoWhite from '$assets/leo-logo-white.png';
import { FeatureFlags } from '$/featureFlags';

export type LeoHeaderLogoProps = {
  variant?: 'light' | 'dark';
};

export const LeoHeaderLogo: VFC<LeoHeaderLogoProps> = ({ variant = 'dark' }) => (
  <img
    src={variant === 'dark' ? leoLogo : leoLogoWhite}
    style={{
      width: '100%',
      maxWidth: FeatureFlags.NewLogin ? '452px' : '600px',
    }}
  />
);
