import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { LoadingOverlay } from "../../ui/LoadingSpinner";
import { getFirebaseErrorMessage, useFirebaseAuthContext, usePasswordLoginForm, } from '@sprice237/react-auth';
import { PasswordLoginForm } from './PasswordLoginForm';
export const ConnectedPasswordLoginForm = memo(() => {
    const { redirectError } = useFirebaseAuthContext();
    const { error, inProgress, submit } = usePasswordLoginForm();
    const redirectErrorMessage = redirectError ? getFirebaseErrorMessage(redirectError) : undefined;
    const errorMessage = error !== null && error !== void 0 ? error : redirectErrorMessage;
    return (_jsx(LoadingOverlay, Object.assign({ isLoading: inProgress }, { children: _jsx(PasswordLoginForm, { error: errorMessage, onSubmit: submit }, void 0) }), void 0));
});
