var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import Button from '@mui/material/Button';
import { useSignInWithRedirect, FacebookAuthProvider } from '@sprice237/react-auth';
const StyledButton = styled(Button) `
  width: 250px;
  height: 50px;
  display: flex;
  flex-direction: row;
`;
const IconWrapper = styled.div `
  margin: 5px 10px 5px 0;

  & img,
  svg {
    height: 36px;
    width: 36px;
    margin: -6px;
  }
`;
export const FacebookLoginButton = ({ onClick, onSuccess, onError, }) => {
    const signInWithRedirect = useSignInWithRedirect();
    const handleClick = () => __awaiter(void 0, void 0, void 0, function* () {
        onClick === null || onClick === void 0 ? void 0 : onClick.call(null);
        try {
            const provider = new FacebookAuthProvider();
            const result = yield signInWithRedirect(provider);
            onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess.call(null, result);
        }
        catch (error) {
            onError === null || onError === void 0 ? void 0 : onError.call(null, error);
        }
    });
    return (_jsxs(StyledButton, Object.assign({ variant: "outlined", onClick: handleClick }, { children: [_jsx(IconWrapper, { children: _jsxs("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", height: "800", width: "1200", viewBox: "-204.79995 -341.33325 1774.9329 2047.9995" }, { children: [_jsx("path", { d: "M1365.333 682.667C1365.333 305.64 1059.693 0 682.667 0 305.64 0 0 305.64 0 682.667c0 340.738 249.641 623.16 576 674.373V880H402.667V682.667H576v-150.4c0-171.094 101.917-265.6 257.853-265.6 74.69 0 152.814 13.333 152.814 13.333v168h-86.083c-84.804 0-111.25 52.623-111.25 106.61v128.057h189.333L948.4 880H789.333v477.04c326.359-51.213 576-333.635 576-674.373", fill: "#1877f2" }, void 0), _jsx("path", { d: "M948.4 880l30.267-197.333H789.333V554.609C789.333 500.623 815.78 448 900.584 448h86.083V280s-78.124-13.333-152.814-13.333c-155.936 0-257.853 94.506-257.853 265.6v150.4H402.667V880H576v477.04a687.805 687.805 0 00106.667 8.293c36.288 0 71.91-2.84 106.666-8.293V880H948.4", fill: "#fff" }, void 0)] }), void 0) }, void 0), _jsx("div", { children: "Sign in with Facebook" }, void 0)] }), void 0));
};
