import styled from 'styled-components';

export const ListItem = styled.li<{ isSelected?: boolean; noHover?: boolean }>`
  ${({ isSelected }) => (isSelected ? 'background-color: #eee !important;' : '')}
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  ${({ noHover }) => (!noHover ? 'cursor: pointer;' : '')}

  &:hover {
    background-color: ${({ isSelected, noHover }) => {
      if (noHover) {
        return 'transparent';
      }
      return isSelected ? '#fff' : '#ddd';
    }} !important;
  }

  &:first-child {
    border-top: 1px solid black;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

export const ListItemContent = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 20px;
`;
