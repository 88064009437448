import React from 'react';
import dayjs from 'dayjs';
import { useFieldGroup, useProfilePageContext } from '@quiet-sunset/leo-shared';
import { HelpTextButton } from '$cmp/helpTextButton/helpTextButton';
import { ProfileContentFieldGroup } from './fieldGroups/profileContentFieldGroup';
import Card from '@mui/material/Card';
import { isSingular, singular } from 'pluralize';

const listHelpText = `
Many data fields require multiple capability, meaning there could be more than one entry for a data field.  To make one
or multiple entries for a section, click ADD ITEM, click the pencil in the right margin, an entry field will appear,
type in the entry you want to make (for instance VEHICLE #1), Click SUBMIT, click on the entry you made, the data field
will open for more definitive information to be entered.  For a second entry, repeat the steps above and enter the next
multiple.  In the future, if subscriber needs to delete a multiple entry, click on the trash can icon in the right margin.`;

export const ProfileContentPage: React.FunctionComponent = () => {
  const {
    rootFieldGroupId,
    rootProfileFieldGroupListItemId,
    isListChildItem,
    lastSaveDate,
    isSaving,
  } = useProfilePageContext();

  const profileFieldGroup = useFieldGroup(rootFieldGroupId);

  const getSingularLabel = (label: string) =>
    isSingular(label) ? `${label} entry` : singular(label);

  const singularLabel = profileFieldGroup ? getSingularLabel(profileFieldGroup.label) : 'entry';

  return (
    <>
      {profileFieldGroup?.is_list && (
        <div>
          <h5 style={{ fontWeight: 'bold' }}>
            Multiple Entry Page{' '}
            <HelpTextButton title="Multiple Entry Page" helpText={listHelpText} />
          </h5>
          <p>
            Click on &ldquo;+ Add New&rdquo; below to add a new {singularLabel}, then click on the
            pencil to name it.
            <br />
            After you have named the new {singularLabel}, click on its name in this list to enter
            its details.
            <br />
            Repeat this process for each {singularLabel}.
          </p>
        </div>
      )}

      <div>
        {!isSaving && (
          <span>
            Last Save: {lastSaveDate != null ? dayjs(lastSaveDate).format('LLL') : 'never'}
          </span>
        )}
        {isSaving && (
          <div>
            <i className="far fa-save animate-flicker leo-save-icon" />
          </div>
        )}
      </div>

      <Card variant="outlined" sx={{ borderRadius: '15px' }}>
        <ProfileContentFieldGroup
          fieldGroupId={rootFieldGroupId}
          isListChildItem={isListChildItem}
          profileFieldGroupListItemId={rootProfileFieldGroupListItemId}
        />
      </Card>
    </>
  );
};
