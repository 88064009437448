import { useRef } from 'react';
export const useUtilitiesService = () => {
    const { current: UtilitiesService } = useRef({
        objectToFormData(obj, formDataParameter = new FormData(), namespace = '') {
            const formData = formDataParameter !== null && formDataParameter !== void 0 ? formDataParameter : new FormData();
            if (obj instanceof Date) {
                formData.append(namespace, obj.toISOString());
            }
            else if (Array.isArray(obj)) {
                if (namespace == null) {
                    throw new Error("Can't have top level array");
                }
                for (let i = 0; i < obj.length; ++i) {
                    this.objectToFormData(obj[i], formData, `${namespace}[${i}]`);
                }
            }
            else if (obj instanceof File) {
                formData.append(namespace, obj);
            }
            else if (typeof obj === 'object') {
                for (const propertyName in obj) {
                    if (Object.prototype.hasOwnProperty.call(obj, propertyName)) {
                        const newPropertyName = namespace == null ? propertyName : `${namespace}[${propertyName}]`;
                        this.objectToFormData(obj[propertyName], formData, newPropertyName);
                    }
                }
            }
            else {
                formData.append(namespace, obj);
            }
            return formData;
        },
    });
    return UtilitiesService;
};
