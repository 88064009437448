import React, { VFC } from 'react';
import { FieldGroup } from '@quiet-sunset/leo-shared';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';

export type FieldGroupListItemProps = {
  fieldGroup: FieldGroup;
};

export const FieldGroupListItem: VFC<FieldGroupListItemProps> = ({ fieldGroup }) => {
  return (
    <>
      <Avatar
        sx={{
          height: 16,
          width: 16,
          bgcolor: 'blue',
          marginRight: 2,
        }}
      >
        {' '}
      </Avatar>
      <ListItemText primary={`Field group: ${fieldGroup.label}`} />
    </>
  );
};
