import React, { FormEventHandler, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useUpdateEmail } from '@sprice237/react-auth-dom';
import { LoadingSpinner } from '$cmp/loadingSpinner';
import { NoUserModeEnabledAlert } from '$cmp/noUserModeEnabledAlert';
import { CardBackdrop } from '$cmp/cardBackdrop';
import { CardContainer } from '$cmp/cardContainer';
import { Card } from '$cmp/card';
import { TextField } from '$cmp/form/textField';
import { useCurrentUserContext } from '$contexts/currentUserContext';
import { Box, Grid, Modal } from '@mui/material';
import { ButtonContainer } from '$cmp/buttonContainer';

export const UserEmailRoute: React.FC = () => {
  const { currentUser, firebaseUser, reloadCurrentUser } = useCurrentUserContext();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [confirmedEmail, setConfirmedEmail] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [password, setPassword] = useState('');
  const [hasError, setHasError] = useState(false);

  const updateEmail = useUpdateEmail();

  const isFormValid =
    currentUser &&
    firebaseUser &&
    email &&
    email !== currentUser?.email &&
    email === confirmedEmail;

  const onSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    if (isFormValid) {
      setIsModalVisible(true);
    }
  };

  const onSubmitModalForm: FormEventHandler = async (ev) => {
    ev.preventDefault();

    if (!isFormValid || !currentUser || !firebaseUser || !password) {
      return;
    }

    setHasError(false);
    setIsLoading(true);
    setIsModalVisible(false);

    try {
      await updateEmail(password, email);
      await reloadCurrentUser();
    } catch (e) {
      setHasError(true);
    } finally {
      setPassword('');
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}

      {currentUser && (
        <CardBackdrop>
          <CardContainer>
            <NoUserModeEnabledAlert />

            <Card>
              <form onSubmit={onSubmit}>
                <h3 style={{ paddingTop: '15px', paddingBottom: '15px' }}>Change email address</h3>

                <div className="form-group row">
                  <label className="col-md-3">Current email address</label>
                  <div className="col-md-9">{currentUser.email}</div>
                </div>

                <div className="form-group row">
                  <label className="col-md-3">New email address</label>
                  <div className="col-md-8">
                    <TextField
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="w-75"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-md-3">Confirm new email address</label>
                  <div className="col-md-8">
                    <TextField
                      value={confirmedEmail}
                      onChange={(e) => setConfirmedEmail(e.target.value)}
                      className="w-75"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="offset-md-3 col-md-3">
                    <Button variant="primary" type="submit" disabled={!isFormValid || isLoading}>
                      Submit
                    </Button>
                  </div>
                </div>
              </form>

              {hasError && (
                <div style={{ color: 'red' }}>There was an error. Please try again.</div>
              )}
            </Card>
          </CardContainer>

          <Modal open={isModalVisible}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{ height: '100%', width: '100%' }}
            >
              <Grid item style={{ width: '500px' }}>
                <Card>
                  <Box m={5}>
                    <form onSubmit={onSubmitModalForm}>
                      <h4>Re-enter your password</h4>

                      <div>For security, you must enter your password to make this change.</div>

                      <Box my={3}>
                        <TextField
                          name="password"
                          type="password"
                          label="Password"
                          variant="standard"
                          fullWidth
                          required
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </Box>

                      <ButtonContainer style={{ display: 'flex', flexDirection: 'row' }}>
                        <Button type="submit" color="primary" disabled={isLoading || !password}>
                          Submit
                        </Button>

                        <Button
                          type="button"
                          color="secondary"
                          variant="contained"
                          onClick={() => setIsModalVisible(false)}
                        >
                          Cancel
                        </Button>
                      </ButtonContainer>
                    </form>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Modal>
        </CardBackdrop>
      )}
    </>
  );
};
