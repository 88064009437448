import React from 'react';
import { Redirect, Route, Switch } from 'react-router';

import { useCurrentUserContext } from '$contexts/currentUserContext';

import { ActivationRoute } from '$routes/accounts/activationRoute';
import { AuthActionRoute } from '$routes/auth/authActionRoute';
import { EmailVerificationRoute } from '$routes/auth/emailVerificationRoute';
import { ForgotPasswordRoute } from '$routes/auth/forgotPasswordRoute';
import { LeoProControlPanelRoute } from '$routes/leoPro/controlPanel/leoProControlPanelRoute';
import { LeoProManagementRoute } from '$routes/leoPro/leoProManagementRoute';
import { LeoProOrganizationRegistrationRequestRoute } from '$routes/leoPro/registrationRequest/leoProOrganizationRegistrationRequestRoute';
import { LoginRoute } from '$routes/auth/loginRoute';
import { HomeRoute } from '$routes/home/homeRoute';
import { ManageLeoProPaymentAccountRoute } from '$routes/manageLeoProPaymentAccount/manageLeoProPaymentAccountRoute';
import { ManagePaymentAccountRoute } from '$routes/managePaymentAccount/managePaymentAccountRoute';
import { PasswordResetRoute } from '$routes/auth/passwordResetRoute';
import { ProfilesRoute } from '$routes/accounts/profilesRoute';
import { ProfileViewerRoute } from '$routes/profiles';
import { RegistrationRoute } from '$routes/auth/registrationRoute';
import { SupportRoute } from '$routes/support/supportRoute';
import { UserSettingsRoute } from '$routes/userSettings/userSettingsRoute';
import { UsersRoute } from '$routes/users/usersRoute';
import { ProfileAdminRoute } from '$routes/profile-admin/profileAdminRoute';
import { AccountsRoute } from '$routes/accounts/accountsRoute';
import { MobileRedirectRoute } from '$routes/mobileRedirectRoute';
import { UserEmailRoute } from '../routes/userSettings/userEmailRoute';

export const Router: React.FunctionComponent = () => {
  const { firebaseUser, isLoggedIn } = useCurrentUserContext();

  const isLoggedOut = !isLoggedIn && !firebaseUser;
  const isEmailVerificationRequired = !isLoggedIn && firebaseUser && !firebaseUser.emailVerified;

  return (
    <Switch>
      <Route exact path="/mobile-redirect" component={MobileRedirectRoute} />
      <Route exact path="/support" component={SupportRoute} />

      {(isLoggedOut || isEmailVerificationRequired) && (
        <Route exact path="/auth-action" component={AuthActionRoute} />
      )}
      {isLoggedOut && <Route exact path="/login" component={LoginRoute} />}

      {isLoggedOut && <Route exact path="/forgot-password" component={ForgotPasswordRoute} />}
      {isLoggedOut && <Route exact path="/reset-password" component={PasswordResetRoute} />}
      {isLoggedOut && <Route exact path="/register" component={RegistrationRoute} />}
      {isLoggedOut && (
        <Route exact path="/register/leo-pro">
          <Redirect to="/register" />
        </Route>
      )}

      {isLoggedOut && <Redirect to="/login" />}

      {isEmailVerificationRequired && (
        <Route exact path="/verify-email" component={EmailVerificationRoute} />
      )}
      {isEmailVerificationRequired && <Redirect to="/verify-email" />}

      {isLoggedIn && <Route exact path="/activate" component={ActivationRoute} />}
      {isLoggedIn && <Route exact path="/home" component={HomeRoute} />}
      {isLoggedIn && <Route exact path="/accounts" component={AccountsRoute} />}
      {isLoggedIn && <Route exact path="/profiles" component={ProfilesRoute} />}
      {isLoggedIn && (
        <Route
          exact
          path="/leo-pro/register"
          component={LeoProOrganizationRegistrationRequestRoute}
        />
      )}
      {isLoggedIn && <Route exact path="/leo-pro" component={LeoProManagementRoute} />}
      {isLoggedIn && (
        <Route exact path="/leo-pro/:leoProOrganizationId" component={LeoProControlPanelRoute} />
      )}
      {isLoggedIn && (
        <Route
          exact
          path="/leo-pro/:leoProOrganizationId/manage-payment-account"
          component={ManageLeoProPaymentAccountRoute}
        />
      )}
      {isLoggedIn && <Route path="/profile-admin" component={ProfileAdminRoute} />}
      {isLoggedIn && <Route path="/profiles/:profileId" component={ProfileViewerRoute} />}
      {isLoggedIn && (
        <Route exact path="/executorProfiles">
          Executor profiles
        </Route>
      )}
      {isLoggedIn && <Route exact path="/users" component={UsersRoute} />}
      {isLoggedIn && <Route exact path="/userSettings" component={UserSettingsRoute} />}
      {isLoggedIn && <Route exact path="/userEmail" component={UserEmailRoute} />}
      {isLoggedIn && (
        <Route exact path="/manage-payment-account" component={ManagePaymentAccountRoute} />
      )}
      {isLoggedIn && <Redirect to="/home" />}
    </Switch>
  );
};
