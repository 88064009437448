import React from 'react';
import { useProfilePageMenuItemContext } from '@quiet-sunset/leo-shared';
import styled from 'styled-components';

const BreadcrumbsList = styled.ul`
  list-style: none;
  padding: 0;
`;

const BreadcrumbsListItem = styled.li`
  display: inline;

  &:not(:first-child):before {
    padding: 8px;
    content: '>';
  }
`;

export const ProfilePageStackBreadcrumbs: React.FunctionComponent = () => {
  const { pageStack, popToIndex } = useProfilePageMenuItemContext();

  return (
    <BreadcrumbsList>
      {pageStack.map((pageStackItem, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <BreadcrumbsListItem key={i}>
          {/* eslint-disable-next-line no-script-url */}
          <a href="javascript:void(0)" onClick={() => popToIndex(i)} style={{ fontSize: '1.4rem' }}>
            {pageStackItem.label}
          </a>
        </BreadcrumbsListItem>
      ))}
    </BreadcrumbsList>
  );
};
