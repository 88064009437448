import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormik } from 'formik';
import { memo } from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { ButtonContainer } from "../../ui/ButtonContainer";
const initialValues = {
    email: '',
    password: '',
    passwordConfirm: '',
};
export const PasswordRegistrationForm = memo(({ error, onSubmit }) => {
    const formik = useFormik({
        initialValues,
        onSubmit,
    });
    return (_jsxs("form", Object.assign({ onSubmit: formik.handleSubmit }, { children: [error && (_jsx(Alert, Object.assign({ severity: "error" }, { children: error }), error)), _jsx(Box, Object.assign({ my: 3 }, { children: _jsx(TextField, { name: "email", type: "email", label: "Email Address", variant: "standard", fullWidth: true, required: true, value: formik.values.email, onChange: formik.handleChange }, void 0) }), void 0), _jsx(Box, Object.assign({ my: 3 }, { children: _jsx(TextField, { name: "password", type: "password", label: "Password", variant: "standard", fullWidth: true, required: true, value: formik.values.password, onChange: formik.handleChange }, void 0) }), void 0), _jsx(Box, Object.assign({ my: 3 }, { children: _jsx(TextField, { name: "passwordConfirm", type: "password", label: "Confirm password", variant: "standard", fullWidth: true, required: true, value: formik.values.passwordConfirm, onChange: formik.handleChange }, void 0) }), void 0), _jsx(ButtonContainer, { children: _jsx(Button, Object.assign({ type: "submit", variant: "contained", color: "primary" }, { children: "Submit" }), void 0) }, void 0)] }), void 0));
});
