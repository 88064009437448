import React, { createContext, FC, useContext, useEffect, useState, useCallback } from 'react';
import type { User } from '@quiet-sunset/leo-shared';
import {
  useFirebaseAuthContext,
  FirebaseUser,
  FirebaseError,
  useSignOut,
} from '@sprice237/react-auth-dom';
import { useAuthService, isAxiosError } from '@quiet-sunset/leo-shared';
import { LoadingSpinner } from '$cmp/loadingSpinner';

export type CurrentUserContextValue = {
  isLoggedIn: boolean;
  currentUser: User | null;
  firebaseUser: FirebaseUser | null;
  reloadCurrentUser: () => Promise<void>;
  redirectError: FirebaseError | undefined;
};

const CurrentUserContext = createContext<CurrentUserContextValue>(null!);
CurrentUserContext.displayName = 'CurrentUserContext';

export const useCurrentUserContext = (): CurrentUserContextValue => useContext(CurrentUserContext);

export const CurrentUserContextProvider: FC = ({ children }) => {
  const { user: firebaseUser, redirectError, retrieveToken } = useFirebaseAuthContext();
  const signOut = useSignOut();

  const AuthService = useAuthService();
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [isInitialized, setIsInitialized] = useState(false);

  const reloadCurrentUser = useCallback(async () => {
    try {
      await retrieveToken(true);
      const authState = await AuthService.getAuthState();
      setCurrentUser(authState.currentUser);
      setIsInitialized(true);
    } catch (e) {
      // if we got a 401 from the API but we have a firebase user and their email address
      // is not verified, THAT'S OK, we want to allow that, because the user needs to get
      // to the email verification screen. if we have a firebase user with a verified
      // email address or we don't have a firebase user at all, we're in a recoverable
      // error state (recovered by logging out). all other cases are errors.

      if (isAxiosError(e) && e.response?.status === 401) {
        setCurrentUser(null);
        if (firebaseUser?.emailVerified !== false) {
          await signOut();
        }
        setIsInitialized(true);
      } else {
        throw e;
      }
    }
  }, [firebaseUser, retrieveToken]);

  useEffect(() => {
    reloadCurrentUser().catch(console.error);
  }, [firebaseUser, reloadCurrentUser]);

  if (!isInitialized) {
    return <LoadingSpinner />;
  }

  const contextValue = {
    currentUser,
    firebaseUser,
    isLoggedIn: !!currentUser,
    reloadCurrentUser,
    redirectError,
  };

  return <CurrentUserContext.Provider value={contextValue}>{children}</CurrentUserContext.Provider>;
};
