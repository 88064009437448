import React from 'react';
import { ThemeProvider } from 'styled-components/macro';
import { CssBaseline } from '@mui/material';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

import createTheme from '$material-app/theme';

import { AppContent } from './appContent';
import { EnvironmentAlertWrapper } from './environmentAlertWrapper';

export const App: React.FunctionComponent = () => {
  const theme = createTheme('DEFAULT');

  return (
    <EnvironmentAlertWrapper>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AppContent />
        </ThemeProvider>
      </MuiThemeProvider>
    </EnvironmentAlertWrapper>
  );
};
