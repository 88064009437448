import React from 'react';
import splashStep1 from '$assets/splash-step-1.png';
import splashStep2 from '$assets/splash-step-2.png';
import splashStep3 from '$assets/splash-step-3.png';
import splashStep4 from '$assets/splash-step-4.png';

export const ProfileSplashPageRoute: React.FunctionComponent = () => (
  <div className="leo-empty-profile">
    <img alt="LEO" src={splashStep1} />
    <img alt="LEO" src={splashStep2} />
    <img alt="LEO" src={splashStep3} />
    <img alt="LEO" src={splashStep4} />
  </div>
);
