import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { AuthActionHandler, AuthActionParams } from '@sprice237/react-auth-dom';
import { useCurrentUserContext } from '$contexts/currentUserContext';

export const AuthActionRoute: React.VFC = () => {
  const history = useHistory();
  const { reloadCurrentUser } = useCurrentUserContext();

  const onNoAction = () => {
    history.push('/');
  };

  const onResetPasswordAction = ({ oobCode }: AuthActionParams) => {
    history.push(`/reset-password?oobCode=${oobCode}`);
  };

  const onVerifyEmailAction = ({ continueUrl }: AuthActionParams) => {
    if (continueUrl) {
      window.location.href = continueUrl;
      return;
    }
    reloadCurrentUser();
  };

  useEffect(() => {
    reloadCurrentUser().catch(console.error);
  }, []);

  return (
    <AuthActionHandler
      onNoAction={onNoAction}
      onResetPasswordAction={onResetPasswordAction}
      onVerifyEmailAction={onVerifyEmailAction}
    />
  );
};
