import React from 'react';
import {
  FacebookLoginButton,
  GoogleLoginButton,
  MicrosoftLoginButton,
  ConnectedPasswordLoginForm,
} from '@sprice237/react-auth-dom';
import { AuthContentWrapper } from '$cmp/authContentWrapper';
import { ButtonContainer } from '$cmp/buttonContainer';
import { LeoHeaderLogo } from '$cmp/leoHeaderLogo';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { AuthContentContainer } from '$cmp/authContentContainer';
import { FeatureFlags } from '$/featureFlags';

export const LoginRoute: React.FunctionComponent = React.memo(() => {
  return (
    <AuthContentWrapper>
      <AuthContentContainer>
        <div style={{ marginBottom: '20px' }}>
          <LeoHeaderLogo />
        </div>

        <h1>
          <span style={{ display: 'inline-block', fontFamily: '"Playfair Display", serif' }}>
            Welcome.&nbsp;
          </span>
          <span style={{ display: 'inline-block', fontFamily: '"Playfair Display", serif' }}>
            Please sign in
          </span>
        </h1>

        {FeatureFlags.PasswordAuth && <ConnectedPasswordLoginForm />}
        <ButtonContainer>
          {FeatureFlags.PasswordAuth && FeatureFlags.NewLogin && (
            <Box>
              <Button component={Link} to="/forgot-password" color="primary">
                Forgot password
              </Button>{' '}
              |{' '}
              <Button component={Link} to="/register" color="primary">
                Register
              </Button>
            </Box>
          )}
          {FeatureFlags.PasswordAuth && !FeatureFlags.NewLogin && (
            <>
              <Box>
                <Button component={Link} to="/forgot-password" color="primary">
                  Forgot password
                </Button>
              </Box>
              <Box>
                <Button component={Link} to="/register" color="primary">
                  Try our 30-day free trial!
                </Button>
              </Box>
            </>
          )}
          {FeatureFlags.GoogleAuth && <GoogleLoginButton onError={(e) => console.error(e)} />}
          {FeatureFlags.MicrosoftAuth && <MicrosoftLoginButton />}
          {FeatureFlags.FacebookAuth && <FacebookLoginButton />}
        </ButtonContainer>
      </AuthContentContainer>
    </AuthContentWrapper>
  );
});
