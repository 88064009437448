import React, { useCallback, useEffect, useState, VFC } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { useProfileFieldGroupListItems } from '@quiet-sunset/leo-shared';
import { LoadingSpinner } from '$cmp/loadingSpinner';

export type RenameFieldGroupListItemDialogProps = {
  profileId: string;
  fieldGroupId: string;
  parentProfileFieldGroupListItemId: string | null;
  profileFieldGroupListItemId: string;
  onClose: () => void;
};

export const RenameFieldGroupListItemDialog: VFC<RenameFieldGroupListItemDialogProps> = ({
  profileId,
  fieldGroupId,
  parentProfileFieldGroupListItemId,
  profileFieldGroupListItemId,
  onClose,
}) => {
  const [label, setLabel] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const [
    profileFieldGroupListItems,
    _addProfileFieldGroupListItem,
    _removeProfileFieldGroupListItem,
    _setProfileFieldGroupListItemTitle,
  ] = useProfileFieldGroupListItems(fieldGroupId, parentProfileFieldGroupListItemId);

  const profileFieldGroupListItem = profileFieldGroupListItems?.find(
    ({ id }) => id === profileFieldGroupListItemId
  );

  useEffect(() => {
    if (profileFieldGroupListItem) {
      setIsLoading(false);
      setLabel(profileFieldGroupListItem.title ?? '');
    }
  }, [profileFieldGroupListItem]);

  const submit = useCallback(async () => {
    setIsLoading(true);
    _setProfileFieldGroupListItemTitle(profileFieldGroupListItemId, label);
    setIsLoading(false);
    onClose();
  }, [profileId, fieldGroupId, profileFieldGroupListItemId, label]);

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <Dialog open fullWidth onClose={onClose}>
        <DialogTitle>Name/rename item</DialogTitle>
        <DialogContent>
          <Stack gap={5}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                submit();
              }}
            >
              <TextField
                label="Name"
                fullWidth
                variant="standard"
                value={label}
                onChange={(e) => setLabel(e.target.value)}
                autoFocus
              />
            </form>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={submit}>Submit</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
