import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormik } from 'formik';
import { memo } from 'react';
import { Link } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useForgotPasswordForm } from '@sprice237/react-auth';
import { ButtonContainer } from "../../ui/ButtonContainer";
import { LoadingOverlay } from "../../ui/LoadingSpinner";
const initialValues = {
    email: '',
};
export const ForgotPasswordForm = memo(({ loginRoutePath }) => {
    const { error, inProgress, isComplete, submit } = useForgotPasswordForm();
    const formik = useFormik({
        initialValues,
        onSubmit: submit,
    });
    return (_jsx(LoadingOverlay, Object.assign({ isLoading: inProgress }, { children: _jsx("form", Object.assign({ onSubmit: formik.handleSubmit }, { children: _jsxs(Box, Object.assign({ mx: 10, my: 5 }, { children: [error && _jsx(Alert, Object.assign({ severity: "error" }, { children: error }), void 0), isComplete && (_jsx(Alert, Object.assign({ severity: "success" }, { children: "Please check your email for a password recovery message." }), void 0)), _jsx(Box, Object.assign({ my: 5 }, { children: _jsx(TextField, { name: "email", type: "email", label: "Email Address", variant: "standard", fullWidth: true, required: true, value: formik.values.email, onChange: formik.handleChange }, void 0) }), void 0), _jsxs(ButtonContainer, { children: [_jsx(Button, Object.assign({ type: "submit", variant: "contained", color: "primary" }, { children: "Submit" }), void 0), _jsx(Button, Object.assign({ component: Link, to: loginRoutePath, color: "primary" }, { children: "Back to login" }), void 0)] }, void 0)] }), void 0) }), void 0) }), void 0));
});
