import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import dayjs from 'dayjs';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { User, useUserService } from '@quiet-sunset/leo-shared';

import { LoadingSpinner } from '$cmp/loadingSpinner';

type UserGridRow = User & {
  status: string;
  billed_quantity?: number;
  amount?: string;
  current_period_start?: Date;
  current_period_end?: Date;
  trial_start?: Date;
  trial_end?: Date;
  has_payment_source?: boolean;
};

const getUserStatus = (user: User) => {
  if (!user.is_active) {
    return 'inactive';
  }
  if (user.is_free) {
    return 'free';
  }
  if (!user.stripe_info?.status) {
    return 'new';
  }
  return user.stripe_info.status;
};

export const SingleSubscriberUserTable: React.FunctionComponent<{
  users: User[];
  onUpdate: () => unknown;
}> = ({ users, onUpdate }) => {
  const UserService = useUserService();

  const [showNonbillableUsers, setShowNonbillableUsers] = useState(false);
  const [showDetailedColumns, setShowDetailedColumns] = useState(false);
  const [userGridRows, setUsers] = useState(null as UserGridRow[] | null);
  const [isUpdating, setIsUpdating] = useState(false);

  const setUserAccessType = useCallback(
    async (userId: string, accessType: string) => {
      setIsUpdating(true);
      try {
        await UserService.setUserAccessType(userId, accessType);
      } finally {
        setIsUpdating(false);
      }
      onUpdate();
    },
    [onUpdate]
  );

  useEffect(() => {
    void (async () => {
      const newUserGridRows = users
        .filter((user) => user.is_single_subscriber_enabled)
        .map((user) => {
          const stripeInfo = user.stripe_info;
          const leoProfileItem = stripeInfo?.item;
          const interval = leoProfileItem?.interval;
          const amount =
            leoProfileItem?.amount != null
              ? `$${(leoProfileItem.amount / 100).toFixed(2)}`
              : undefined;

          const userGridRow: UserGridRow = {
            ...user,
            status: getUserStatus(user),
            billed_quantity: leoProfileItem?.quantity,
            amount: amount != null && interval != null ? `${amount}/${interval}` : undefined,
            current_period_start: stripeInfo
              ? new Date(stripeInfo.current_period_start * 1000)
              : undefined,
            current_period_end: stripeInfo
              ? new Date(stripeInfo.current_period_end * 1000)
              : undefined,
            trial_start:
              stripeInfo?.trial_start != null ? new Date(stripeInfo.trial_start * 1000) : undefined,
            trial_end:
              stripeInfo?.trial_end != null ? new Date(stripeInfo.trial_end * 1000) : undefined,
            has_payment_source: stripeInfo?.has_payment_source,
          };

          return userGridRow;
        })
        .filter((x) => showNonbillableUsers || !['free', 'new', 'inactive'].includes(x.status));
      setUsers(newUserGridRows);
    })();
  }, [users, showNonbillableUsers]);

  const columns = useMemo<GridColumns<UserGridRow>>(
    () =>
      [
        ...(showDetailedColumns ? [{ field: 'id', headerName: 'ID', width: 300 }] : []),
        { field: 'email', headerName: 'Email', width: 250 },
        { field: 'first_name', headerName: 'First name', width: 150 },
        { field: 'last_name', headerName: 'Last name', width: 150 },
        { field: 'status', headerName: 'Status', width: 100 },
        {
          field: 'created_date',
          headerName: 'Signup date',
          width: 100,
          renderCell: ({ value }) => (value != null ? dayjs.utc(value).local().format('L') : ''),
        },
        {
          field: 'last_activity_date',
          headerName: 'Last activity date',
          width: 125,
          renderCell: ({ value }) => (value != null ? dayjs.utc(value).local().format('L') : ''),
        },
        ...(showDetailedColumns
          ? ([
              {
                field: 'has_payment_source',
                headerName: 'Has payment source',
                width: 150,
                valueGetter: ({ value }) => (value ? 'Yes' : 'No'),
              },
              { field: 'num_profiles', headerName: 'Qty', width: 75 },
              {
                field: 'billed_quantity',
                headerName: 'Billed qty',
                width: 100,
              },
              { field: 'amount', headerName: 'amount', width: 125 },
              {
                field: 'trial_start',
                headerName: 'Trial start',
                width: 100,
                renderCell: ({ value }) =>
                  value != null ? dayjs.utc(value).local().format('L') : '',
              },
              {
                field: 'trial_end',
                headerName: 'Trial end',
                width: 100,
                renderCell: ({ value }) =>
                  value != null ? dayjs.utc(value).local().format('L') : '',
              },
              {
                field: 'current_period_start',
                headerName: 'Current period start',
                width: 150,
                renderCell: ({ value }) =>
                  value != null ? dayjs.utc(value).local().format('L') : '',
              },
              {
                field: 'current_period_end',
                headerName: 'Current period end',
                width: 150,
                renderCell: ({ value }) =>
                  value != null ? dayjs.utc(value).local().format('L') : '',
              },
            ] as GridColumns<UserGridRow>)
          : []),
        {
          field: 'actions',
          headerName: 'Actions',
          width: 250,
          renderCell: ({ row: user }) => (
            <>
              {!user.stripe_info &&
                !['new', 'trialing', 'active', 'past_due'].includes(user.status) && (
                  <Button onClick={() => setUserAccessType(user.id, 'paid')}>Make paid</Button>
                )}
              {user.status !== 'free' && (
                <Button onClick={() => setUserAccessType(user.id, 'free')}>Make free</Button>
              )}
              {user.status !== 'inactive' && (
                <Button variant="danger" onClick={() => setUserAccessType(user.id, 'inactive')}>
                  Make inactive
                </Button>
              )}
            </>
          ),
        },
      ] as GridColumns<UserGridRow>,
    [showDetailedColumns]
  );

  if (userGridRows == null) {
    return null;
  }

  return (
    <>
      {isUpdating && <LoadingSpinner />}
      <div>
        <label>
          <input
            type="checkbox"
            checked={showNonbillableUsers}
            onChange={(e) => setShowNonbillableUsers(e.target.checked)}
          />{' '}
          Show free/new/inactive users
        </label>
      </div>
      <div>
        <label>
          <input
            type="checkbox"
            checked={showDetailedColumns}
            onChange={(e) => setShowDetailedColumns(e.target.checked)}
          />{' '}
          Show detailed columns
        </label>
      </div>
      <div style={{ height: '400px' }}>
        <DataGrid columns={columns} rows={userGridRows} />
      </div>
    </>
  );
};
