import React from 'react';
import { useFieldGroupItems } from '@quiet-sunset/leo-shared';
// eslint-disable-next-line import/no-cycle
import { ProfileContentFieldGroupVerticalFieldGroupItem } from './profileContentFieldGroupVerticalFieldGroupItem';
import { List, ListItem, ListItemContent } from '$cmp/list';
import { ProfileContentFieldGroupItemDependencyChecker } from '../profileContentFieldGroupItemDependencyChecker';

export interface ProfileContentFieldGroupVerticalProps {
  fieldGroupId: string;
  profileFieldGroupListItemId: string | null;
}

export const ProfileContentFieldGroupVertical: React.FunctionComponent<ProfileContentFieldGroupVerticalProps> =
  (props) => {
    const { fieldGroupId, profileFieldGroupListItemId } = props;

    const fieldGroupItems = useFieldGroupItems(fieldGroupId);

    if (!fieldGroupItems) {
      return null;
    }

    return (
      <List transparent style={{ margin: '30px' }}>
        {fieldGroupItems.map((fieldGroupItem) => (
          <ProfileContentFieldGroupItemDependencyChecker
            key={fieldGroupItem.id}
            fieldGroupItem={fieldGroupItem}
            profileFieldGroupListItemId={profileFieldGroupListItemId}
          >
            <ListItem noHover>
              <ListItemContent style={{ padding: 0 }}>
                <div style={{ flex: 1 }}>
                  <ProfileContentFieldGroupVerticalFieldGroupItem
                    fieldGroupItem={fieldGroupItem}
                    profileFieldGroupListItemId={profileFieldGroupListItemId}
                  />
                </div>
              </ListItemContent>
            </ListItem>
          </ProfileContentFieldGroupItemDependencyChecker>
        ))}
      </List>
    );
  };
