import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { ProfileContextProvider, ProfileNavigatorContextProvider } from '@quiet-sunset/leo-shared';

import { ProfileContainer } from './profileContainer';

export const ProfileViewerRoute: React.FunctionComponent = () => {
  const {
    params: { profileId },
    url: baseRoute,
  } = useRouteMatch<{ profileId: string }>();

  return (
    <ProfileContextProvider baseRoute={baseRoute} profileId={profileId}>
      <ProfileNavigatorContextProvider>
        <ProfileContainer />
      </ProfileNavigatorContextProvider>
    </ProfileContextProvider>
  );
};
