import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormik } from 'formik';
import { memo } from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import { ButtonContainer } from "../../ui/ButtonContainer";
const initialValues = {
    email: '',
    password: '',
    remember: false,
};
export const PasswordLoginForm = memo(({ error, onSubmit }) => {
    const formik = useFormik({
        initialValues,
        onSubmit: onSubmit,
    });
    return (_jsxs("form", Object.assign({ onSubmit: formik.handleSubmit }, { children: [error && _jsx(Alert, Object.assign({ severity: "error" }, { children: error }), void 0), _jsx(Box, Object.assign({ my: 3 }, { children: _jsx(TextField, { name: "email", type: "email", label: "Email Address", variant: "standard", fullWidth: true, required: true, value: formik.values.email, onChange: formik.handleChange }, void 0) }), void 0), _jsx(Box, Object.assign({ my: 3 }, { children: _jsx(TextField, { name: "password", type: "password", label: "Password", variant: "standard", fullWidth: true, required: true, value: formik.values.password, onChange: formik.handleChange }, void 0) }), void 0), _jsx(FormControlLabel, { control: _jsx(Checkbox, { name: "remember", checked: formik.values.remember, onClick: formik.handleChange }, void 0), label: "Remember me" }, void 0), _jsx(ButtonContainer, { children: _jsx(Button, Object.assign({ type: "submit", variant: "contained", color: "primary" }, { children: "Sign in" }), void 0) }, void 0)] }), void 0));
});
